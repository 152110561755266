// import React, { useEffect, useContext, useState, useRef } from "react";
// import ImageGallery from "react-image-gallery";
// import Slider from "react-slick";
// import { findIndex } from "lodash";
// import "react-image-gallery/styles/css/image-gallery.css";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import FullscreenIcon from "@material-ui/icons/Fullscreen";

// /*Full screen dialog*/

// import { makeStyles } from "@material-ui/core/styles";
// import Button from "@material-ui/core/Button";
// import Dialog from "@material-ui/core/Dialog";
// import AppBar from "@material-ui/core/AppBar";
// import Toolbar from "@material-ui/core/Toolbar";
// import IconButton from "@material-ui/core/IconButton";
// import Typography from "@material-ui/core/Typography";
// import CloseIcon from "@material-ui/icons/Close";
// import Slide from "@material-ui/core/Slide";

// function NextArrow(props) {
//   const { className, style, onClick } = props;
//   return (
//     <div
//       className={className}
//       style={{ ...style, display: "block" }}
//       onClick={onClick}
//     >
//       <i className="fas fa-angle-right"></i>
//     </div>
//   );
// }

// function PrevArrow(props) {
//   const { className, style, onClick } = props;
//   return (
//     <div
//       className={className}
//       style={{ ...style, display: "block" }}
//       onClick={onClick}
//     >
//       <i className="fas fa-angle-left"></i>
//     </div>
//   );
// }

// const useStyles = makeStyles((theme) => ({
//   appBar: {
//     position: "relative",
//   },
//   title: {
//     marginLeft: theme.spacing(2),
//     flex: 1,
//   },
// }));

// const Transition = React.forwardRef(function Transition(props, ref) {
//   return <Slide direction="up" ref={ref} {...props} />;
// });

// // export default function FullScreenDialog() {

// //   return (
// //     <div>
// //       <Button variant="outlined" color="primary" onClick={handleClickOpen}>
// //         Open full-screen dialog
// //       </Button>
// //     </div>
// //   );
// // }

// const PropertyImageGallery = (props) => {
//   const [active, setActive] = useState("");
//   const [concatGalleryPhotos, setConcatGalleryPhotos] = useState([]);
//   const galleryRef = useRef();
//   const galleryRefFullScreen = useRef();

//   useEffect(() => {
//     let galleryPhotos = [];
//     if (props.lable) {
//       if (Object.keys(props.lable).length > 0) {
//         Object.keys(props.lable).map((labelName) => {
//           if (labelName !== "All Photos") {
//             props.lable[labelName].map((photoObject) => {
//               galleryPhotos.push({
//                 ...photoObject,
//                 label: labelName,
//               });
//             });
//           }
//         });
//       }
//       setConcatGalleryPhotos(galleryPhotos);
//       if (galleryPhotos.length > 0) {
//         setActive(galleryPhotos[0].label);
//       }
//     }
//   }, [props.lable]);

//   const settings = {
//     dots: false,
//     infinite: true,
//     arrows: true,
//     speed: 500,
//     // slide: 'div',
//     // className: "slick-slider-custom",
//     centerMode: false,
//     slidesToShow: 6,
//     variableWidth: true,
//     adaptiveHeight: true,
//     slidesToScroll: 1,
//     nextArrow: <NextArrow />,
//     prevArrow: <PrevArrow />,
//     responsive: [
//       {
//         breakpoint: 1024,
//         settings: {
//           slidesToShow: 5,
//         },
//       },
//       {
//         breakpoint: 767,
//         settings: {
//           slidesToShow: 4,
//         },
//       },
//       {
//         breakpoint: 480,
//         settings: {
//           slidesToShow: 2,
//         },
//       },
//     ],
//   };

//   const fsSettings = {
//     dots: false,
//     infinite: false,
//     arrows: true,
//     speed: 500,
//     // slide: 'div',
//     // className: "slick-slider-custom",
//     centerMode: false,
//     slidesToShow: 9,
//     variableWidth: true,
//     adaptiveHeight: false,
//     slidesToScroll: 1,
//     nextArrow: <NextArrow />,
//     prevArrow: <PrevArrow />,
//     responsive: [
//       {
//         breakpoint: 1024,
//         settings: {
//           slidesToShow: 5,
//         },
//       },
//       {
//         breakpoint: 767,
//         settings: {
//           slidesToShow: 4,
//         },
//       },
//       {
//         breakpoint: 480,
//         settings: {
//           slidesToShow: 2,
//           variableWidth: true,
//         },
//       },
//     ],
//   };

//   // useEffect(() => {
//   //   setActiveGallery(props.lable[active]);
//   // }, [active]);

//   const onSelected = (e) => {
//     setActive(e.target.name);
//     let firstIndex = concatGalleryPhotos.findIndex(
//       (photoObject) => photoObject.label === e.target.name
//     );
//     if (firstIndex !== -1) {
//       openFullView
//         ? galleryRefFullScreen.current?.slideToIndex(firstIndex)
//         : galleryRef.current?.slideToIndex(firstIndex);
//     } else {
//       openFullView
//         ? galleryRefFullScreen.current?.slideToIndex(0)
//         : galleryRef.current?.slideToIndex(0);
//     }
//   };

//   /*Full Screen Dialog */

//   const classes = useStyles();
//   const [openFullView, setOpenFullView] = React.useState(false);

//   useEffect(() => {
//     if (active) {
//       if (openFullView) {
//         setActive(concatGalleryPhotos[0].label);
//       } else {
//         let firstIndex = concatGalleryPhotos.findIndex(
//           (photoObject) => photoObject.label === active
//         );
//         galleryRef.current?.slideToIndex(firstIndex);
//       }
//     }
//   }, [openFullView]);

//   const handleClickFullViewOpen = () => {
//     setOpenFullView(true);
//   };

//   const handleCloseFullView = () => {
//     setOpenFullView(false);
//   };

//   const handleGalleryImageChange = (currentIndex) => {
//     let selectedObject = concatGalleryPhotos[currentIndex];
//     if (active !== selectedObject.label) {
//       setActive(selectedObject.label);
//     }
//   };

//   return (
//     <>
//       <div className="pvg-wrapper mb-5 mt-3">
//         <div className="img-gallery-head">
//           {props.width && !props.groupAuction && (
//             <div className="property-head1">Image Gallery</div>
//           )}
//           {!props.groupAuction &&
//             props.lable !== undefined &&
//             Object.keys(props.lable).length && (
//               <Slider {...settings}>
//                 {Object.keys(props.lable)
//                   .filter((label) => label !== "All Photos")
//                   .map((text) => (
//                     <>
//                       <div className="item">
//                         <a
//                           onClick={onSelected}
//                           name={text}
//                           rel="noreferrer"
//                           className={active === text ? "active" : ""}
//                         >
//                           {text}
//                         </a>
//                       </div>
//                     </>
//                   ))}
//               </Slider>
//             )}
//         </div>
//         {concatGalleryPhotos && concatGalleryPhotos.length !== 0 && (
//           <div className="imgGlryWrpr">
//             <ImageGallery
//               items={concatGalleryPhotos}
//               thumbnailPosition="bottom"
//               showNav={true}
//               ref={galleryRef}
//               showBullets={false}
//               showFullscreenButton={false}
//               showPlayButton={false}
//               onSlide={handleGalleryImageChange}
//             />
//             <Button
//               className="fullScreenIcon"
//               onClick={handleClickFullViewOpen}
//             >
//               View Full Screen
//             </Button>
//             {/* <FullscreenIcon
//               className='fullScreenIcon'
//               onClick={handleClickFullViewOpen}
//             /> */}
//           </div>
//         )}
//       </div>
//       <div className="fullScreenView">
//         <Dialog
//           className="fullScreenView"
//           fullScreen
//           open={openFullView}
//           onClose={handleCloseFullView}
//           TransitionComponent={Transition}
//         >
//           <div className="pvg-wrapper mb-5 mt-3">
//             <IconButton
//               className="closeIconBtn"
//               edge="start"
//               color="inherit"
//               onClick={handleCloseFullView}
//               aria-label="close"
//             >
//               <CloseIcon />
//             </IconButton>
//             <div className="img-gallery-head">
//               {props.lable !== undefined && Object.keys(props.lable).length && (
//                 <Slider {...fsSettings}>
//                   {Object.keys(props.lable)
//                     .filter((label) => label !== "All Photos")
//                     .map((text) => (
//                       <div className="item">
//                         <a
//                           onClick={onSelected}
//                           name={text}
//                           rel="noreferrer"
//                           className={active === text ? "active" : ""}
//                         >
//                           {text}
//                         </a>
//                       </div>
//                     ))}
//                 </Slider>
//               )}
//             </div>
//             {concatGalleryPhotos && concatGalleryPhotos.length !== 0 && (
//               <ImageGallery
//                 items={concatGalleryPhotos}
//                 thumbnailPosition="bottom"
//                 showNav={true}
//                 ref={galleryRefFullScreen}
//                 showBullets={false}
//                 showFullscreenButton={false}
//                 showPlayButton={false}
//                 onSlide={handleGalleryImageChange}
//               />
//             )}
//           </div>
//         </Dialog>
//       </div>
//     </>
//   );
// };

// export default PropertyImageGallery;

import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const PropertyImageGallery = (props) => {
  const [openFullView, setOpenFullView] = useState(false);

  const handleClickFullViewOpen = () => {
    setOpenFullView(true);
  };

  const handleCloseFullView = () => {
    setOpenFullView(false);
  };

  const imageUrl =
    props.data && props.data.avatar
      ? `${global.image_url}uploads/product/${props.data.avatar}`
      : "";

  return (
    <>
      <div className="pvg-wrapper mb-5 mt-3">
        {imageUrl && (
          <div className="imgGlryWrpr">
            <img
              src={imageUrl}
              alt="Property"
              style={{ width: "100%", height: "435px", objectFit: "contain" }}
            />
            {/* <Button
              className="fullScreenIcon"
              onClick={handleClickFullViewOpen}
            >
              View Full Screen
            </Button> */}
          </div>
        )}
      </div>
      <Dialog
        fullScreen
        open={openFullView}
        onClose={handleCloseFullView}
        TransitionComponent={Transition}
      >
        <div className="pvg-wrapper mb-5 mt-3">
          <IconButton
            className="closeIconBtn"
            edge="start"
            color="inherit"
            onClick={handleCloseFullView}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          {imageUrl && (
            <img
              src={imageUrl}
              alt="Property Full Screen"
              style={{ width: "100%", height: "435px", objectFit: "contain" }}
            />
          )}
        </div>
      </Dialog>
    </>
  );
};

export default PropertyImageGallery;
