import React, { useContext, useEffect, useState } from "react";
import FavoriteCheckbox from "../FavoriteCheckbox";
import { useHistory, useLocation } from "react-router-dom";
import GlobalContext from "../../../context/GlobalContext";
import "./search-auction-card.css";
import {
  numberWithCommas,
  setDecimalPoint,
  onImageError,
  onPropertyImageError,
  getTimeInterval,
} from "../../../utils";
import { abbrState } from "../../../utils/stateToAbbr";
import moment from "moment";

const SearchAuctionCard = ({ item, props }) => {
  let history = useHistory();
  let location = useLocation().search;
  let newUrl = new URLSearchParams(location);
  const { currentTime } = useContext(GlobalContext);
  const [timer, setTimer] = useState({});
  const store_id = window.location.pathname.split("/")[1];

  useEffect(() => {
    currentTime.get &&
      setTimer(
        getTimeInterval(
          item.group_auction_start,
          item.group_auction_end,
          currentTime.get,
          item.market_status,
          item.offer_type,
        ),
      );
  }, [currentTime.get]);
  return (
    <div
      className="auction-card"
      onClick={() => {
        if (item.site_id == global?.storeDetails?.site_id) {
          history.push({
            pathname: `/auction-group/${item.id}`,
            search: "",
          });
        } else {
          window.open(
            `${
              item.domain !== "" ? item.domain : "/" + item.store_id
            }/auction-group/${item.id}`,
          );
        }
      }}
      style={{ cursor: "pointer" }}
    >
      <div className="left">
        <div className="location-title mb-2">
          <span>{item.group_title}</span>
          {/* <b>{item.state}</b> */}
        </div>
        <img
          src={
            item.avatar === null
              ? "/images/noimage.png"
              : `${process.env.REACT_APP_IMAGE_URL}uploads/product/${item.avatar}`
          }
          alt=""
          onError={onPropertyImageError}
          //   className="grid-card-img"
        />
      </div>
      <div className="right">
        <div className="timer mb-2">
          {!timer.isCompleted && <i className="far fa-clock"></i>}{" "}
          {Object.keys(timer) !== 0 && timer.isCompleted ? (
            <span className="text-capitalize">
              {item.market_status === "open" ? "Ended" : item.listing_status}
            </span>
          ) : timer.text === "Bidding starts" ? (
            <>
              {timer.status +
                " " +
                moment(item?.start_date).format("MM/DD/YY") +
                " at " +
                moment(item?.start_date).format("LT")}
            </>
          ) : (
            `${timer.status} ${timer.days}D ${timer.hours}H ${timer.minutes}:${timer.seconds}`
          )}
        </div>
        <div className="body mb-2">
          <div className="body-row">
            <span className="label-bold">
              {item.group_ids?.split(",").length}
            </span>
            <span className="label">Number of houses</span>
          </div>
          <div className="body-row">
            <span className="label-bold">
              {item.oprice ? item.oprice : "-"}
            </span>
            <span className="label">Starting price</span>
          </div>
        </div>
        <div className="">
          <a className="btn btn-primary">Make an Offer</a>
        </div>
      </div>
      {/* <div className="nf-list">
       
      </div>
    

      <div className="card-body">
        <div className="grid-bottom">
          <div className="itmPrc">
            $
            {item.offer_type === "Offer" && item.offer === 1
              ? setDecimalPoint(item.oprice)
              : "-"}
          </div>
          <div className="itmAdrs">{item.loc_address.split(",")[0]},</div>
          <div className="itmCty">
            {item.loc_city},{" "}
            {abbrState(item.state, "abbr")
              ? abbrState(item.state, "abbr")
              : item.state}{" "}
            {item.zipcode || ""}
          </div>
          <div className="clearfix itmSpecs">
            {item.listing_type !== 2 && item.bedroom_type !== ""
              ? `${item.bedroom_type} Bed | `
              : null}
            {item.listing_type !== 2 && item.bathroom !== ""
              ? `${item.bathroom} Bath | `
              : null}
            {item.listing_type && item.sqrtarea
              ? `${numberWithCommas(item.sqrtarea)} ${
                  item.listing_type == 2 ? "Acres" : "sq. ft."
                }`
              : null}
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default SearchAuctionCard;
