import React, { useState, useEffect, useContext, useMemo, useRef } from "react";
import Layout from "../../../components/molecules/Layout";
import PropertyLeftTopMobile from "../../../components/molecules/Property/PropertyLeftTopMobile";
import PropertyBids from "../../../components/molecules/Property/PropertyBids";
import PropertyComments from "../../../components/molecules/Property/PropertyComments";
import GroupAuctionRight from "../../../components/molecules/GroupAuction/GroupAuctionRight";
import GroupAuctionLeft from "../../../components/molecules/GroupAuction/GroupAuctionLeft";
import socket from "../../../utils/socket";
import {
  getPropertyDetails,
  getBidHistory,
  watchList,
  addtoCart,
  submitOffer,
  beforeReg,
} from "../../../API/propertyDetails";
import "./groupauction.css";
import GlobalContext from "../../../context/GlobalContext";
import NotFound from "../NotFound";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import RuumrModal from "../../../components/molecules/Modal";
import DOMPurify from "dompurify";
import {
  numberWithCommas,
  setDecimalPoint,
  getTimeInterval,
  onImageError,
  onPropertyImageError,
} from "../../../utils";
import { getAllNotes } from "../../../API/notes";
import { before, filter, findIndex, flatten, orderBy, uniqBy } from "lodash";
import { useHistory, useLocation } from "react-router-dom";
import CustomInput from "../../../components/atoms/Inputs/CustomInput";
import { getSearch } from "../../../API/search";

import Button from "@material-ui/core/Button";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";
import copy from "copy-to-clipboard";
import moment from "moment";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import fileSaver from "file-saver";
import axios from "../../../utils/axiosconfig";
import Dialog from "../../../components/atoms/Dialog";
import CircularProgress from "@material-ui/core/CircularProgress";
import { object } from "yup/lib/locale";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import { Helmet } from "react-helmet";
import Fade from "@material-ui/core/Fade";
import PrimaryButton from "../../../components/atoms/PrimaryButton";
import CustomDialog from "../../../components/atoms/CustomDialog";
import { Formik, useFormik } from "formik";
import isAuthenticate from "../../../utils/auth";
import CheckBox from "../../../components/atoms/CheckBox";
import * as Yup from "yup";
import * as mainaxios from "axios";

const GroupAuctionView = (props) => {
  const { id } = props.match.params;
  const {
    currentTime,
    comment,
    userDetails,
    bid,
    userLoggedIn,
    userLocation,
    propertyAwarded,
    bidIncrement,
  } = useContext(GlobalContext);
  const Clipboard = useRef();
  const history = useHistory();
  const location = useLocation();
  const [rtbCompleted, setRtbCompleted] = useState(false);
  const [highestBidder, setHighestBidder] = useState("");
  const [rtbFiles, setRtbFiles] = useState({});
  const [getProductDetails, seyProductDetails] = useState({});
  const [selectedGroupPropertyDetails, setSelectedGroupPropertyDetails] =
    useState({});
  const [groupPropertyDetails, setGroupDetails] = useState([]);
  const [showComp, setShowComp] = useState(null);
  const [getMatterPort, setMatterPort] = useState([]);
  const [showMatterport, setShowMatterport] = useState(null);
  const [agentDetails, setAgentDetails] = useState({});
  const [timer, setTimer] = useState({});
  const [buyTimer, setBuyTimer] = useState({});
  const [offerTimer, setOfferTimer] = useState({});
  const [getSellerData, setSellerData] = useState({});
  const [notes, setNotes] = useState([]);
  const [lable, setLable] = useState({});
  const [comments, setComments] = useState([]);
  const [noComments, setNoComments] = useState(0);
  const [nextBidAmount, setNextBidAmount] = useState("");
  const [follow, setFollow] = useState(false);
  const [isPropertyRemove, setIsPropertyRemove] = useState(false);
  const [step, setStep] = useState(1);
  const [mobile, setMobile] = useState("");
  const [myClientsList, setMyClientsList] = useState([]);
  const [myAgent, setMyAgent] = useState([]);
  const [fav, setFav] = useState(0);
  const [shareFollow, setShareFollow] = useState(false);
  const [similarProperty, setSimilarProperty] = useState([]);
  const [isUserBid, setUserBid] = useState(0);
  const [mobileNumberVal, setMobileNumberVal] = useState(false);
  const [agentInfo, setAgentInfo] = useState({});
  const [modalopen, setModalOpen] = useState({});
  const [currentPropCity, setCurrentPropCity] = useState({});
  const bidContainer = useRef();
  const bookTour = useRef();
  const commentsRef = useRef();
  const sellerTermsCon = useRef(null);
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const [offlineMarked, setOfflineMarked] = useState(false);
  const [modalopenBid, setModelopenBid] = useState(false);
  const [tourCount, setTourCount] = useState(0);
  const [open, setOpen] = React.useState(false);
  const audio = useMemo(() => new Audio("/audio/good_notification"), []);
  const anchorRef = React.useRef(null);
  const imgRef = React.useRef(null);
  const prevOpen = React.useRef(open);
  const [soldDetails, setSoldDetails] = useState("");
  const [heightChange, setHeightChange] = useState("442");

  const [docUri, setDocUri] = useState("");
  const [beforePopup, setBeforePopup] = useState(false);
  const [submittedPopup, setSubmittedPopup] = useState(false);
  const [validMessage, setMessage] = useState({
    message: "",
    type: "",
  });

  const validationArray = Yup.object({
    email: Yup.string().required(`Enter Email`).email("Enter Valid Email"),
    isAgent: Yup.string().required(`Required`),
    need_agent: Yup.string().when("isAgent", {
      is: (isAgent) => isAgent == "false",
      then: Yup.string().required("Required"),
    }),
  });
  const store_id = global?.storeDetails?.id;
  const site_id = global?.storeDetails?.site_id;

  // ("View home inspection", "Ask a question", "Ask about terms", "Get bidding updates", "Schedule tour", "View documents")

  const beforeFormik = useFormik({
    initialValues: {
      email: "",
      isAgent: "",
      rtbCheck: "true",
      propertyID: id,
      listing_tier: "",
      rtbType: "",
      need_agent: "",
    },
    validationSchema: validationArray,
    onSubmit: async (values) => {
      console.log("values", values);
      values.rtbType =
        beforePopup == "home_inspection"
          ? "View home inspection"
          : beforePopup == "schedule_tour"
            ? "Schedule tour"
            : beforePopup == "bidding_update"
              ? "Get bidding updates"
              : beforePopup == "view_docs"
                ? "View documents"
                : beforePopup == "ask_question"
                  ? "Ask a question"
                  : beforePopup == "seller_question"
                    ? "Ask about terms"
                    : "";
      values.listing_tier = getProductDetails?.listing_tier;
      let data = await beforeReg(values, site_id);
      console.log("data values on submit", data);
      if (data && data.success === true) {
        console.log("data.message", data.message);
        if (data.message != "Account created successfully!") {
          toast.success(data.message);
        }
        setBeforePopup(false);
        localStorage.setItem("userToken", data.token);
        userLoggedIn.set(true);

        // redirect user
        switch (beforePopup) {
          case "home_inspection":
            openFile(
              `uploads/cerify_product/${getProductDetails.certify_name}`,
            );
            break;
          case "schedule_tour":
            if (getProductDetails.book_tour) {
              addTourCount(getProductDetails?.id);
              window.open(encodeURI(getProductDetails.book_tour));
            } else {
              clickSchedule();
            }
            break;
          case "bidding_update":
            addFav({
              project_id: id,
              mobile: mobile.length === 0 ? "" : mobile,
            });
            break;
          case "view_docs":
            window.open(encodeURI(docUri));
            break;
          case "ask_question":
            setSubmittedPopup("ask_question");
            break;
          case "seller_question":
            setSubmittedPopup("seller_question");
            break;
        }
      }
    },
  });

  useEffect(() => {
    let url = new URLSearchParams(location.search);
    if (url.get("showDocs") == "true") {
      setOpen(true);
    }
    if (url.get("showComment") == "true") {
      setTimeout(() => {
        clickAskQuestion();
      }, 2500);
    }
  }, [location]);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  useEffect(() => {
    if (beforePopup == false) {
      beforeFormik.setFieldValue("email", "");
      beforeFormik.setFieldValue("isAgent", "");
      beforeFormik.setFieldValue("need_agent", "");
      beforeFormik.handleReset();
      console.log("before formik values", beforeFormik);
    }
  }, [beforePopup]);

  useEffect(() => {
    if (beforeFormik?.values?.isAgent === "true") {
      beforeFormik.setFieldValue("need_agent", "");
    }
  }, [beforeFormik.values?.isAgent]);

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };
  // console.log(userDetails.get.value, "userDetails.get");

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  const width = useMediaQuery("(min-width:1200px)");

  // useEffect(() => {
  //   if (Object.keys(userLocation.get).length !== 0 && id !== undefined) {
  //     getData();
  //     getNotes();
  //   }
  // }, [userLocation.get, id]);
  useEffect(() => {
    if (
      bidIncrement.get &&
      !!Object.keys(bidIncrement.get).length &&
      id === bidIncrement.get.productId
    ) {
      setNextBidAmount(bidIncrement.get["nextBid"]);
    }
  }, [bidIncrement.get, id]);

  useEffect(() => {
    console.log(id, "+++++++++++++++++++");
    if (id !== undefined) {
      getData();
    }
  }, [id]);

  useEffect(() => {
    if (selectedGroupPropertyDetails != undefined) {
      getNotes();
    }
  }, selectedGroupPropertyDetails);

  useEffect(() => {
    if (follow === false) {
      setStep(1);
    }
  }, [follow]);

  useEffect(() => {
    if (
      Object.keys(propertyAwarded.get).length !== 0 &&
      propertyAwarded.get["project_id"] == id
    ) {
      if (propertyAwarded.get["winner"] == userDetails.get["id"]) {
        toast.success(
          `Congratulations! You won the property for $${setDecimalPoint(
            propertyAwarded.get["amount"],
          )}`,
        );
      } else {
        if (isUserBid !== 0) {
          toast.warning(`Unfortunately! You have lost this property`);
        }
      }
      seyProductDetails((prv) => ({
        ...prv,
        market_status: "sold",
      }));
      setSoldDetails({
        ...soldDetails,
        highest_amount: propertyAwarded.get["amount"],
        listingFormat: propertyAwarded.get["listingFormat"],
        user_id: propertyAwarded.get["winner"],
      });
      setHighestBidder(propertyAwarded.get["winner"]);
      propertyAwarded.set({});
    }
  }, [userDetails.get, propertyAwarded.get]);

  useEffect(() => {
    if (Object.keys(bid.get).length !== 0) {
      if (bid.get["productId"] == id) {
        getBidHistorys();
      }
      if (bid.get["productId"] == id) {
        if (!!Object.keys(bid.get).length) {
          setHighestBidder(bid.get.highBidder);
        }
        if (
          !!Object.keys(bid.get).length &&
          !!Object.keys(userDetails.get).length &&
          bid.get["highBidder"] != userDetails.get["id"] &&
          isUserBid !== 0 &&
          bid.get["noRefresh"] != true
        ) {
          toast.info("You are losing.");
        }
        if (
          !!Object.keys(bid.get).length &&
          !!Object.keys(userDetails.get).length &&
          bid.get["highBidder"] === userDetails.get["id"]
        ) {
          toast.info(bid.get.message);
          toast.success("Bid placed successfully!");
        }
        setNextBidAmount(bid.get["nextBid"]);
        seyProductDetails((prv) => ({
          ...prv,
          wprice: bid.get["wPrice"],
        }));
      }

      if (
        bid.get["productId"] == id &&
        bid.get["userId"] == userDetails.get["id"]
      ) {
        setUserBid((prv) => prv + 1);
      }
      bid.set({});
    }
  }, [userDetails.get, bid.get]);

  console.log(
    "PUKA SET TIMER",
    getProductDetails["date_added"],
    getProductDetails["date_closed"],
    currentTime.get,
    getProductDetails["market_status"],
  );

  useEffect(() => {
    if (
      Object.keys(bid.get).length !== 0 &&
      Number(bid.get.belowFive) === 1 &&
      bid.get["productId"] == id
    ) {
      getProductDetails["date_closed"] = bid.get.belowFiveIncrement;
    }
    if (imgRef.current?.complete) {
      setIsImageLoaded(true);
    }

    Object.keys(getProductDetails).length !== 0 &&
      currentTime.get &&
      setTimer(
        getTimeInterval(
          getProductDetails["date_added"],
          getProductDetails["date_closed"],
          currentTime.get,
          getProductDetails["market_status"],
        ),
      );

    if (Object.keys(getProductDetails).length !== 0 && currentTime.get) {
      if (getProductDetails.buynow == 1) {
        setBuyTimer(
          getTimeInterval(
            getProductDetails["b_date_added"],
            getProductDetails["b_date_closed"],
            currentTime.get,
            getProductDetails["market_status"],
          ),
        );
      }
    }

    if (Object.keys(getProductDetails).length !== 0 && currentTime.get) {
      if (getProductDetails.offer == 1) {
        setOfferTimer(
          getTimeInterval(
            getProductDetails["o_date_added"],
            getProductDetails["o_date_closed"],
            currentTime.get,
            getProductDetails["market_status"],
          ),
        );
      }
    }
  }, [getProductDetails, currentTime.get, bid.get]);

  useEffect(() => {
    if (
      (Object.keys(comment.get).length !== 0) &
      (comment.get["product_id"] === id)
    ) {
      let newComment = comment.get;
      if (newComment.comment_id !== "0") {
        let position = comments.findIndex(
          (comment) => parseInt(comment.id) === parseInt(newComment.comment_id),
        );
        if (position !== -1) {
          let existingComments = comments;
          let existingComment = existingComments[position];
          let rply = existingComment["rply"];
          if (rply) {
            let rplyIndex = rply.findIndex(
              (rply) => parseInt(rply.id) === parseInt(newComment.id),
            );
            if (rplyIndex !== -1) {
              rply[rplyIndex].status = newComment.status;
              existingComment["rplyCount"] =
                newComment.status === "true"
                  ? existingComment["rplyCount"] + 1
                  : existingComment["rplyCount"] - 1;
            } else {
              rply = [{ from: "comments", ...newComment }, ...rply];
              existingComment["rply"] = rply;
              existingComment["rplyCount"] = existingComment["rplyCount"] + 1;
            }
            existingComments[position] = existingComment;
            setComments([...existingComments]);
          } else {
            existingComment["rplyCount"] = existingComment["rplyCount"] + 1;
            let newrply = [{ from: "comments", ...newComment }];
            existingComment["rply"] = newrply;
            existingComments[position] = existingComment;
            setComments([...existingComments]);
          }
        } else {
          let flatArray = flatten(
            filter(
              filter(comments, { from: "comments" }).map((ind) => ind.rply),
              (en) => en !== undefined,
            ),
          );
          let position = flatArray.findIndex(
            (flatArray) =>
              parseInt(flatArray.id) === parseInt(newComment.comment_id),
          );
          if (position !== -1) {
            let rplyCommetParent = flatArray[position];
            let parentCommentPosition = comments.findIndex(
              (comment) =>
                parseInt(comment.id) ===
                  parseInt(rplyCommetParent.comment_id) &&
                comment.from === "comments",
            );
            if (parentCommentPosition !== -1) {
              let existingComments = comments;
              let existingComment = existingComments[parentCommentPosition];
              let rply = existingComment["rply"] || [];
              let rplyposition = rply.findIndex(
                (rply) => parseInt(rply.id) === parseInt(newComment.comment_id),
              );
              if (rplyposition !== -1) {
                let parentRplyComments = rply[rplyposition];
                let replay = parentRplyComments.rply;
                if (replay) {
                  let replayIndex = replay.findIndex(
                    (replay) => parseInt(replay.id) === parseInt(newComment.id),
                  );
                  if (replayIndex !== -1) {
                    replay[replayIndex].status = newComment.status;
                    parentRplyComments["rplyCount"] =
                      newComment.status === "true"
                        ? parentRplyComments["rplyCount"] + 1
                        : parentRplyComments["rplyCount"] - 1;
                  } else {
                    replay = [newComment, ...replay];
                    parentRplyComments["rply"] = replay;
                    parentRplyComments["rplyCount"] =
                      parentRplyComments["rplyCount"] + 1;
                  }
                  rply[rplyposition] = parentRplyComments;
                  existingComment["rply"] = rply;
                  existingComments[position] = existingComment;
                  setComments([...existingComments]);
                } else {
                  let newreplay = [newComment];
                  parentRplyComments["rply"] = newreplay;
                  parentRplyComments["rplyCount"] = 1;
                  rply[rplyposition] = parentRplyComments;
                  existingComment["rply"] = rply;
                  existingComments[position] = existingComment;
                  setComments([...existingComments]);
                }
              }
            }
          }
        }
      } else {
        let position = comments.findIndex(
          (comment) => parseInt(comment.id) === parseInt(newComment.id),
        );
        if (position !== -1) {
          let existingComments = comments;
          existingComments[position].status = newComment.status;
          if (newComment.status === "true") {
            setNoComments((preCount) => preCount + 1);
          } else {
            setNoComments((preCount) => preCount - 1);
          }
          setComments(existingComments);
        } else {
          setComments((existingComments) => [
            { from: "comments", rplyCount: 0, ...newComment },
            ...existingComments,
          ]);
          setNoComments((preCount) => preCount + 1);
        }
      }
    }
  }, [comment.get]);

  const toggleFollow = () => {
    setFollow((follow) => !follow);
  };

  const copyToClipBoard = (e) => {
    copy(window.location.href);
    toast.success("Copied to clipboard!");
    toggleShare();
  };

  const toggleShare = () => setShareFollow((prv) => !prv);

  const getBidHistorys = async () => {
    let data = await getBidHistory({ id }, site_id);
    let bidsArr = data.result;

    seyProductDetails((prv) => ({
      ...prv,
      bidcount: bidsArr.length,
      uniqueBidders: data.uniqueBidders,
    }));
    bidsArr = bidsArr.map((element) => ({
      ...element,
      from: "bids",
    }));

    setComments((prevData) =>
      orderBy(
        [...filter(prevData, { from: "comments" }), ...bidsArr],
        (element) => new Date(element.created_at),
        ["desc"],
      ),
    );
  };

  const getSimilarProperty = async (body) => {
    let data = await getSearch(body, site_id);
    if (data) {
      if (data.status === true) {
        let allProducts = data.result["productdetails"];
        let position = findIndex(allProducts, { id: Number(id) });
        if (position !== -1) {
          allProducts.splice(position, 1);
        } else {
          allProducts.splice(0, 1);
        }
        setSimilarProperty(allProducts);
      }
    }
  };

  const getNotes = async () => {
    if (site_id != (null || undefined) && selectedGroupPropertyDetails) {
      const data = await getAllNotes(
        { project_id: selectedGroupPropertyDetails.id },
        selectedGroupPropertyDetails.site_id,
      );
      if (data.status === true) {
        setNotes(data.result);
      }
    }
  };

  const getData = async () => {
    if (site_id != (null || undefined) && id != (null || undefined)) {
      let data = await getPropertyDetails(id, site_id);
      if (data && data.status === true) {
        setRtbCompleted(data.result.RtB_Completed);
        setSoldDetails(data.result?.highBidder[0]);
        setHighestBidder(
          !!data.result?.highBidder.length &&
            Object.keys(data.result?.highBidder[0]).length
            ? data.result.highBidder[0].user_id
            : "",
        );
        if (!!data?.result?.RtB_Completed === false) {
          setRtbFiles(
            !!data?.result?.previous_RtB.length &&
              data?.result?.previous_RtB[0] !== null &&
              Object.keys(data?.result?.previous_RtB[0]).length !== 0
              ? data.result.previous_RtB[0]
              : {},
          );
        } else if (!!data?.result?.RtB_Completed === true) {
          setRtbFiles(
            !!data?.result?.existing_RtB.length &&
              data?.result?.existing_RtB[0] !== null &&
              Object.keys(data?.result?.existing_RtB[0]).length !== 0
              ? data.result.existing_RtB[0]
              : {},
          );
        }
        setIsPropertyRemove(data.is_removed === "true");
        setNextBidAmount(data.result.propertyDetails["next_bid"]);
        let propertyDetails = data.result.propertyDetails;
        propertyDetails["matterport"] = propertyDetails["matterport"]
          ? JSON.parse(propertyDetails["matterport"])
          : {};
        propertyDetails["listing_video"] =
          data.result &&
          data.result.propertyDetails &&
          data.result.propertyDetails.listing_video &&
          JSON.parse(data.result.propertyDetails.listing_video).length > 0
            ? JSON.parse(data.result.propertyDetails.listing_video)
            : [];
        setUserBid(propertyDetails.userBidCount);
        setTourCount(propertyDetails.tour_clicks);
        if (data.result.my_clients)
          setMyClientsList(
            data.result.my_clients.filter(
              (client) => client.purchase_power > 0,
            ),
          );
        if (data.result.my_agent) setMyAgent(data.result.my_agent);
        // getSimilarProperty({
        //   page: 1,
        //   cities: [propertyDetails.loc_city],
        //   limit: 10,
        //   status: "",
        // });
        propertyDetails["views"] = data.result.views || 0;
        propertyDetails["uniqueBidders"] = data.result.uniqueBidders || 0;
        setFav(
          data.result.fav ? (data.result.fav["status"] === "true" ? 1 : 0) : 0,
        );
        setAgentInfo(
          data.result.agent === "" || data.result.agent === null
            ? {}
            : data.result.agent,
        );
        setMobile(
          data.result.fav
            ? data.result.fav["mobile"] !== "" ||
              data.result.fav["mobile"] !== null ||
              data.result.fav["mobile"] !== null
              ? data.result.fav["mobile"]
              : ""
            : "",
        );

        let more = JSON.parse(propertyDetails.resource);
        if (more && Array.isArray(more) && more.length !== 0) {
          let dynamicData = more[0] !== undefined ? more[0] : {};
          let staticData = more[1] !== undefined ? more[1] : {};
          propertyDetails["resource"] = { ...staticData, ...dynamicData };
        }

        let terms = JSON.parse(propertyDetails.sellerTerms);
        if (terms && Array.isArray(terms) && terms.length !== 0) {
          let dynamicData = terms[0] !== undefined ? terms[0] : {};
          let staticData = terms[1] !== undefined ? terms[1] : {};
          let sellerToolTips = Object.values(terms[1]);
          let sellerTermsKeys = Object.keys(terms[0]);
          let sellerTermsValues = Object.values(terms[0]);
          let sellerTermsArr = sellerTermsKeys.map((ele, i) => {
            let sellTerm = {};
            sellTerm["sellerKey"] = ele;
            sellTerm["sellerValue"] = sellerTermsValues[i];
            sellTerm["sellerTooltip"] = sellerToolTips[i];
            sellTerm["checked"] = false;
            return sellTerm;
          });
          propertyDetails["sellerTerms"] = { ...staticData, ...dynamicData };
          propertyDetails["sellerTermsTooltip"] = { ...terms[1] };
          propertyDetails["sellerTermValues"] = sellerTermsArr;
        }

        let aboutProperty = JSON.parse(propertyDetails.aboutProperty);
        if (
          aboutProperty &&
          Array.isArray(aboutProperty) &&
          aboutProperty.length !== 0
        ) {
          let dynamicData =
            aboutProperty[0] !== undefined ? aboutProperty[0] : {};
          let staticData =
            aboutProperty[1] !== undefined ? aboutProperty[1] : {};
          propertyDetails["aboutProperty"] = { ...staticData, ...dynamicData };
        }
        propertyDetails["offer"] = 1;
        seyProductDetails(propertyDetails);

        if (
          data.result.groupPropertyDetails &&
          data.result.groupPropertyDetails.length > 0
        ) {
          setGroupDetails(data.result.groupPropertyDetails);

          let groupDetails = data.result.groupPropertyDetails[0];

          groupDetails["matterport"] = groupDetails["matterport"]
            ? JSON.parse(groupDetails["matterport"])
            : {};

          let more = JSON.parse(groupDetails.resource);
          if (more && Array.isArray(more) && more.length !== 0) {
            let dynamicData = more[0] !== undefined ? more[0] : {};
            let staticData = more[1] !== undefined ? more[1] : {};
            groupDetails["resource"] = { ...staticData, ...dynamicData };
          }

          let terms = JSON.parse(groupDetails.sellerTerms);
          if (terms && Array.isArray(terms) && terms.length !== 0) {
            let dynamicData = terms[0] !== undefined ? terms[0] : {};
            let staticData = terms[1] !== undefined ? terms[1] : {};
            let sellerToolTips = Object.values(terms[1]);
            let sellerTermsKeys = Object.keys(terms[0]);
            let sellerTermsValues = Object.values(terms[0]);
            let sellerTermsArr = sellerTermsKeys.map((ele, i) => {
              let sellTerm = {};
              sellTerm["sellerKey"] = ele;
              sellTerm["sellerValue"] = sellerTermsValues[i];
              sellTerm["sellerTooltip"] = sellerToolTips[i];
              sellTerm["checked"] = false;
              return sellTerm;
            });
            groupDetails["sellerTerms"] = { ...staticData, ...dynamicData };
            groupDetails["sellerTermsTooltip"] = { ...terms[1] };
            groupDetails["sellerTermValues"] = sellerTermsArr;
          }

          let aboutProperty = JSON.parse(groupDetails.aboutProperty);
          if (
            aboutProperty &&
            Array.isArray(aboutProperty) &&
            aboutProperty.length !== 0
          ) {
            let dynamicData =
              aboutProperty[0] !== undefined ? aboutProperty[0] : {};
            let staticData =
              aboutProperty[1] !== undefined ? aboutProperty[1] : {};
            groupDetails["aboutProperty"] = { ...staticData, ...dynamicData };
          }
          setCurrentPropCity({
            lat: groupDetails.lat,
            lng: groupDetails.lng,
            city: groupDetails.loc_city,
            state: groupDetails.state,
          });
          setSelectedGroupPropertyDetails(groupDetails);
        }

        setAgentDetails(
          data.result.propertyDetails["agentOfProperty"]
            ? {
                ...JSON.parse(data.result.propertyDetails["agentOfProperty"]),
                agentUserId: data.result.agent ? data.result.agent.id : "",
              }
            : {},
        );
        let comm = data.result["comments"].map((element) => ({
          ...element,
          from: "comments",
        }));
        let bids = data.result["bids"].map((element) => ({
          ...element,
          from: "bids",
        }));
        setComments(
          orderBy(
            [...comm, ...bids],
            (element) => new Date(element.created_at),
            ["desc"],
          ),
        );
        setNoComments(data.result["commentCount"]);
        setSellerData(
          data.result.propertyDetails["userDetails"]
            ? JSON.parse(data.result.propertyDetails["userDetails"])
            : {},
        );
        let lables = data.result.groupPropertyDetails.map((data) => data.id);
        let lable = {};
        let allPhotos = data.result.groupPropertyDetails.map((data) => {
          return {
            original: `${process.env.REACT_APP_IMAGE_URL}uploads/product/${data.avatar}`,
            thumbnail: `${process.env.REACT_APP_IMAGE_URL}uploads/product/${data.avatar}`,
          };
        });
        lable["Floor"] = allPhotos;
        // lables.forEach((element) => {
        //   let resu =
        //     data.result.images[
        //       findIndex(data.result.images, { asset_label: element })
        //     ];
        //   const url = resu.asset_image ? resu.asset_image.split(",") : [];
        //   lable[element] = url.map((ri) => ({
        //     original: `${process.env.REACT_APP_IMAGE_URL}uploads/product/${ri}`,
        //     thumbnail: `${process.env.REACT_APP_IMAGE_URL}uploads/product/${ri}`,
        //   }));
        // });
        setLable(lable);

        let matterPort = data.result.matterport.map((mp) => ({
          ...mp,
          imgSrc: mp.asset_image.split(","),
        }));
        setMatterPort(matterPort);
        let show = JSON.parse(data.result.propertyDetails.show_matterport);
        setShowMatterport(show);
      }
    }
  };

  useEffect(() => {
    window.addEventListener("online", () => {
      if (offlineMarked) {
        getData();
      }
    });

    window.addEventListener("offline", () => {
      if (!offlineMarked) {
        setOfflineMarked(true);
      }
    });
  }, [offlineMarked]);

  useEffect(() => {
    window.addEventListener("focus", () => {
      if (!socket.connected) {
        getData();
      }
    });

    if (!document.hidden) {
      if (!socket.connected) {
        getData();
      }
    }
  }, [socket.connected, site_id]);

  const openFile = (path) => {
    // fileSaver.saveAs(process.env.REACT_APP_IMAGE_URL + path);
    window.open(process.env.REACT_APP_IMAGE_URL + path, "_blank");
  };

  const addFav = async (body) => {
    let data = await watchList(body, site_id);
    if (data.status === true) {
      if (data.message === "Removed from watchlist") {
        setFav(0);
        toast.success("Property has been unfollowed", {
          preventDuplicate: true,
        });
      } else if (data.message.includes("Watchlist Added")) {
        setFav(1);
        toast.success("Property has been followed", { preventDuplicate: true });
      } else if (data.message.includes("Added to watchlist")) {
        setFav(1);
        toast.success("Property has been followed", { preventDuplicate: true });
      }
    } else {
      toast.error("Something went wrong");
    }
    // setMobile("");
    setFollow(false);
  };

  const favouriteAdd = (e) => {
    setMobile(e.target.value);
    setMobileNumberVal(false);
  };
  const productScroll = () => {
    history.push(`/property/${id}/#bids`);
    location.hash === "#bids" &&
      bidContainer.current.scrollIntoView({ behavior: "smooth" });
  };
  const toggleModalBid = () => {
    console.log("modal bid open status", modalopenBid);
    setModelopenBid(!modalopenBid);
  };

  const rtbFormConfirmation = async () => {
    let res = await axios.post(
      "userproperty/Rtb",
      {
        propertyID: id,
        listing_tier: getProductDetails.listing_tier,
      },
      {
        headers: { site_id },
      },
    );
    try {
      if (res && res.data.success) {
        return res.data;
      }
    } catch (err) {
      console.log(err, "this is err from user property bid");
    }
  };
  const submittingBid = async () => {
    if (userLoggedIn.get) {
      if (userDetails.get["id"] == getProductDetails.user_id) {
        toast.warning("Can't Bid on own property");
      } else {
        if (
          timer.isCompleted === false &&
          timer.text === "Bid Due In" &&
          rtbCompleted
        ) {
          // if (userDetails.get["bid_approval"] === "false") {
          //   toast.error("Your bidding priviledge has been revoked");
          // } else {

          // }
          toggleModalBid();
        } else if (
          (timer.isCompleted === false &&
            timer.text === "Bid Due In" &&
            !rtbCompleted) ||
          (timer.isCompleted === false &&
            timer.text === "Bidding starts" &&
            !rtbCompleted) ||
          (timer.isCompleted === false &&
            timer.text === "Bidding starts" &&
            rtbCompleted &&
            userDetails.get &&
            !!Object.keys(userDetails.get).length)
        ) {
          rtbFormConfirmation();

          history.push({
            pathname:
              (getProductDetails.listing_tier === 1 &&
                userDetails.get.profiletype === "agent") ||
              (getProductDetails.listing_tier === 1 &&
                userDetails.get.profiletype === "builder") ||
              (getProductDetails.listing_tier === 1 &&
                userDetails.get.profiletype === "customer") ||
              (getProductDetails.listing_tier === 2 &&
                userDetails.get.profiletype === "agent")
                ? `/registertobid-success`
                : `/registertobid-form`,
            state: {
              productType: "bidding",
              path: location,
              tier: getProductDetails.listing_tier,
              propertyDtls: { ...getProductDetails, exisingRtb: rtbFiles },
            },
          });
        } else {
          if (timer.isCompleted === true) {
            toast.warning("Closed");
          } else {
            toast.warning("Auction not yet started");
          }
        }
      }
    } else {
      if (!userLoggedIn.get && timer.isCompleted) {
        toast.warning("Closed");
      } else {
        history.push({
          pathname: `/signup`,
          state: {
            productType: "bidding",
            path: location,
            tier: getProductDetails.listing_tier,
            propertyDtls: { ...getProductDetails, exisingRtb: rtbFiles },
          },
        });
      }
    }

    // userLoggedIn.get
    //   ? userDetails.get["id"] == getProductDetails.user_id
    //     ? toast.warning("Can't Bid on own property")
    //     : timer.isCompleted === false && timer.text === "Bid Due In"
    //     ? userDetails.get["bid_approval"] === "false"
    //       ? toast.error("Your bidding priviledges have being revoked")
    //       : toggleModalBid()
    //     : timer.isCompleted === true
    //     ? toast.warning("Closed")
    //     : toast.warning("Auction not yet started")
    //   : history.push({ pathname: "/signup", state: location });
  };
  /*Redirecting to the seller availabilty page or buyer slot booking view*/

  // console.log(getProductDetails, 'getProductDetails')

  const submittingBuy = async () => {
    if (userLoggedIn.get) {
      if (userDetails.get["id"] == getProductDetails.user_id) {
        toast.warning("Can't Bid on own property");
      } else {
        if (
          buyTimer.isCompleted === false &&
          buyTimer.text === "Bid Due In" &&
          rtbCompleted
        ) {
          // if (userDetails.get["bid_approval"] === "false") {
          //   toast.error("Your bidding priviledge has been revoked");
          // } else {

          // }
          toggleModalBid();
        } else if (
          (buyTimer.isCompleted === false &&
            buyTimer.text === "Bid Due In" &&
            !rtbCompleted) ||
          (buyTimer.isCompleted === false &&
            buyTimer.text === "Bidding starts" &&
            !rtbCompleted) ||
          (buyTimer.isCompleted === false &&
            buyTimer.text === "Bidding starts" &&
            rtbCompleted &&
            userDetails.get &&
            !!Object.keys(userDetails.get).length)
        ) {
          rtbFormConfirmation();
          localStorage.setItem("fromPage", "buynow");
          history.push({
            pathname:
              (getProductDetails.listing_tier === 1 &&
                userDetails.get.profiletype === "agent") ||
              (getProductDetails.listing_tier === 1 &&
                userDetails.get.profiletype === "builder") ||
              (getProductDetails.listing_tier === 1 &&
                userDetails.get.profiletype === "customer") ||
              (getProductDetails.listing_tier === 2 &&
                userDetails.get.profiletype === "agent")
                ? `/registertobid-success`
                : `/registertobid-form`,
            state: {
              path: location,
              productType: "buynow",
              from: "buynow",
              tier: getProductDetails.listing_tier,
              propertyDtls: { ...getProductDetails, exisingRtb: rtbFiles },
            },
          });
        } else {
          if (buyTimer.isCompleted === true) {
            toast.warning("Closed");
          } else {
            toast.warning("Auction not yet started");
          }
        }
      }
    } else {
      if (!userLoggedIn.get && buyTimer.isCompleted) {
        toast.warning("Closed");
      } else {
        localStorage.setItem("fromPage", "buynow");
        history.push({
          pathname: `/signup`,
          state: {
            path: location,
            productType: "buynow",
            from: "buynow",
            tier: getProductDetails.listing_tier,
            propertyDtls: { ...getProductDetails, exisingRtb: rtbFiles },
          },
        });
      }
    }
  };

  const submittingOffer = async () => {
    if (userLoggedIn.get) {
      if (userDetails.get["id"] == getProductDetails.user_id) {
        toast.warning("Can't Bid on own property");
      } else {
        if (
          offerTimer.isCompleted === false &&
          offerTimer.text === "Bid Due In" &&
          rtbCompleted
        ) {
          // if (userDetails.get["bid_approval"] === "false") {
          //   toast.error("Your bidding priviledge has been revoked");
          // } else {

          // }
          toggleModalBid();
        } else if (
          (offerTimer.isCompleted === false &&
            offerTimer.text === "Bid Due In" &&
            !rtbCompleted) ||
          (offerTimer.isCompleted === false &&
            offerTimer.text === "Bidding starts" &&
            !rtbCompleted) ||
          (offerTimer.isCompleted === false &&
            offerTimer.text === "Bidding starts" &&
            rtbCompleted &&
            userDetails.get &&
            !!Object.keys(userDetails.get).length)
        ) {
          rtbFormConfirmation();

          history.push({
            pathname:
              (getProductDetails.listing_tier === 1 &&
                userDetails.get.profiletype === "agent") ||
              (getProductDetails.listing_tier === 1 &&
                userDetails.get.profiletype === "builder") ||
              (getProductDetails.listing_tier === 1 &&
                userDetails.get.profiletype === "customer") ||
              (getProductDetails.listing_tier === 2 &&
                userDetails.get.profiletype === "agent")
                ? `/registertobid-success`
                : `/registertobid-form`,
            state: {
              path: location,
              tier: getProductDetails.listing_tier,
              propertyDtls: { ...getProductDetails, exisingRtb: rtbFiles },
            },
          });
        } else {
          if (offerTimer.isCompleted === true) {
            toast.warning("Closed");
          } else {
            toast.warning("Auction not yet started");
          }
        }
      }
    } else {
      if (!userLoggedIn.get && offerTimer.isCompleted) {
        toast.warning("Closed");
      } else {
        history.push({
          pathname: `/signup`,
          state: {
            path: location,
            tier: getProductDetails.listing_tier,
            propertyDtls: { ...getProductDetails, exisingRtb: rtbFiles },
          },
        });
      }
    }
  };

  const clickSchedule = () => {
    if (
      userLoggedIn.set &&
      userDetails.get["id"] == getProductDetails.user_id
    ) {
      history.push(`/sellercalendar`);
    } else {
      addTourCount(getProductDetails?.id);
      bookTour.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
    }
  };

  const clickAskQuestion = () => {
    if (commentsRef.current) {
      commentsRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
    }
  };

  const clickBidNumber = () => {
    if (bidContainer.current) {
      bidContainer.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
    }
  };

  const clickPropertyTable = (propertyID) => {
    console.log(propertyID, "clickPropertyTable");

    if (selectedGroupPropertyDetails.id !== propertyID) {
      if (groupPropertyDetails && groupPropertyDetails.length > 0) {
        let groupDetails = groupPropertyDetails.find(
          (obj) => obj.id === propertyID,
        );

        if (groupDetails) {
          try {
            // Check if `matterport` needs parsing
            groupDetails["matterport"] =
              typeof groupDetails["matterport"] === "string"
                ? JSON.parse(groupDetails["matterport"])
                : groupDetails["matterport"];

            // Check if `resource` needs parsing
            let more =
              typeof groupDetails.resource === "string"
                ? JSON.parse(groupDetails.resource || "[]")
                : groupDetails.resource || [];
            if (Array.isArray(more) && more.length > 0) {
              let dynamicData = more[0] || {};
              let staticData = more[1] || {};
              groupDetails["resource"] = { ...staticData, ...dynamicData };
            }

            // Check if `sellerTerms` needs parsing
            let terms =
              typeof groupDetails.sellerTerms === "string"
                ? JSON.parse(groupDetails.sellerTerms || "[]")
                : groupDetails.sellerTerms || [];
            if (Array.isArray(terms) && terms.length > 0) {
              let dynamicData = terms[0] || {};
              let staticData = terms[1] || {};
              let sellerToolTips = Object.values(terms[1] || {});
              let sellerTermsKeys = Object.keys(terms[0] || {});
              let sellerTermsValues = Object.values(terms[0] || {});
              let sellerTermsArr = sellerTermsKeys.map((ele, i) => ({
                sellerKey: ele,
                sellerValue: sellerTermsValues[i],
                sellerTooltip: sellerToolTips[i],
                checked: false,
              }));
              groupDetails["sellerTerms"] = { ...staticData, ...dynamicData };
              groupDetails["sellerTermsTooltip"] = { ...(terms[1] || {}) };
              groupDetails["sellerTermValues"] = sellerTermsArr;
            }

            // Check if `aboutProperty` needs parsing
            let aboutProperty =
              typeof groupDetails.aboutProperty === "string"
                ? JSON.parse(groupDetails.aboutProperty || "[]")
                : groupDetails.aboutProperty || [];
            if (Array.isArray(aboutProperty) && aboutProperty.length > 0) {
              let dynamicData = aboutProperty[0] || {};
              let staticData = aboutProperty[1] || {};
              groupDetails["aboutProperty"] = { ...staticData, ...dynamicData };
            }

            // Update state with new property details
            setCurrentPropCity({
              lat: groupDetails.lat || null,
              lng: groupDetails.lng || null,
              city: groupDetails.loc_city || "",
              state: groupDetails.state || "",
            });
            setSelectedGroupPropertyDetails(groupDetails);
          } catch (error) {
            console.error("Error processing property data:", error);
          }
        }
      }
    }
  };

  const showSellerStatus = (ref) => {
    sellerTermsCon.current.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "center",
    });
  };

  const addMobileNumberFollow = () => {
    if (mobile.length >= 8 && mobile.length <= 13) {
      mobile && addFav({ project_id: id, mobile: mobile });
    } else if (mobile.length < 8 || mobile.length > 13) {
      setMobileNumberVal(true);
    }
  };

  const constructAddressComponent = (
    address,
    zipcode,
    unitNumber,
    city,
    state,
  ) => {
    var addressArray = address?.split(",");
    if (addressArray?.length > 0) {
      if (!!unitNumber?.length) {
        return (
          <>
            <div className="addressLine1">{`${
              addressArray[0]
            }, ${unitNumber}, ${addressArray[1]}, ${
              addressArray[2]?.trim().split(" ")[0]
            } ${zipcode || ""}`}</div>{" "}
            {/* <span className="addressLine2">{`${addressArray[1]}, ${
              addressArray[2].trim().split(" ")[0]
            } ${zipcode}`}</span> */}
          </>
        );
      } else if (addressArray?.length === 1) {
        return (
          <div className="addressLine1">{`${
            addressArray[0]
          }, ${city}, ${state}, ${zipcode || ""}`}</div>
        );
      } else {
        return (
          <>
            <div className="addressLine1">{`${addressArray[0]}, ${
              addressArray[1]
            }, ${addressArray[2]?.trim().split(" ")[0]} ${
              zipcode || ""
            }`}</div>{" "}
            {/* <span className="addressLine2">{`${addressArray[1]}, ${
              addressArray[2].trim().split(" ")[0]
            } ${zipcode}`}</span> */}
          </>
        );
      }
    } else {
      return address;
    }
  };

  const formatAddress = (getProductDetails) => {
    var tempArr = getProductDetails.loc_address.split(
      getProductDetails.loc_city,
    );
    var tempSubArr2 = tempArr[0]?.trim().split("");
    if (tempSubArr2[tempSubArr2.length - 1] === ",") {
      tempSubArr2.splice(tempSubArr2.length - 1, 1);
    }
    tempSubArr2.push(" " + getProductDetails.house_number + ", ");
    tempArr[0] = tempSubArr2.join("");
    return tempArr.join(getProductDetails.loc_city);
  };

  const onEdit = async (id, text) => {
    if (text && DOMPurify.sanitize(text)) {
      let { data } = await axios.post(
        "editComment",
        {
          id,
          text: DOMPurify.sanitize(text),
        },
        {
          headers: { site_id },
        },
      );
      if (data.status === true) {
        getData();
        toast.success("Comment updated successfully");
      } else {
        toast.success("Something went wrong");
      }
    }
  };

  const OnDelete = async (id) => {
    let { data } = await axios.post(
      "commentDelete",
      { id },
      {
        headers: { site_id },
      },
    );
    if (data.status === true) {
      getData();
      toast.success("Comment deleted successfully");
    } else {
      toast.success("Something went wrong");
    }
  };

  const addTourCount = async (id) => {
    try {
      let { data } = await axios.post(
        "api/tour_count",
        { id },
        {
          headers: { site_id },
        },
      );
      if (data.status) {
        setTourCount(data.tour_count);
      }
    } catch (err) {
      console.log(err, "add tour err");
    }
  };

  const addProductToCart = async (id, bprice) => {
    try {
      let res = await addtoCart(id, bprice, site_id);
      if (res && res.status === true) {
        console.log(res);
        history.push(`/checkout/${id}`);
      } else {
        if (res) {
          toast.warning(res.message);
        }
      }
    } catch (err) {
      toast.warning(err);
      console.log(err, "add tour err");
    }
  };

  useEffect(() => {
    console.log("clientHeight value outside", heightChange);
  }, [heightChange]);

  console.log("beforeFormik", beforeFormik);

  const [cancelRequest, setCancelRequest] = useState(undefined);

  let sourceToken = mainaxios.CancelToken.source();

  const checkEmail = async (cancelToken) => {
    const config = {
      cancelToken: cancelToken?.token,
    };
    let { data } = await axios.post(
      process.env.REACT_APP_API + "checkEmailExists",
      {
        email: beforeFormik.values?.email,
      },
      config,
      {
        headers: { site_id },
      },
    );
    if (data.success === true) {
      setMessage({ message: "Email available", type: "success" });
    } else if (data.success === false) {
      setMessage({ message: "Email already taken", type: "error" });
    }

    console.log("response data ----->", data);
  };

  useEffect(() => {
    if (cancelRequest) {
      cancelRequest.cancel();
    }

    setCancelRequest(sourceToken);
    if (beforeFormik.values?.email != "") {
      checkEmail(sourceToken);
    }
  }, [beforeFormik.values?.email]);

  const handleExistingEmail = () => {
    history.push({
      pathname: `/login`,
      state: {
        fromPage: "productPage",
        values: {
          email: beforeFormik.values.email,
        },
      },
    });
  };

  const handleUpdateClick = (id, email, type) => {
    history.push({
      pathname: `/signup`,
      state: {
        path: location,
        tier: getProductDetails.listing_tier,
        propertyDtls: { ...getProductDetails, exisingRtb: rtbFiles },
        action: "userUpdate",
        user_id: id,
        values: {
          email: email,
        },
      },
    });
  };

  console.log(
    "PUKA GROUP DETAILS",
    selectedGroupPropertyDetails,
    groupPropertyDetails,
    getProductDetails,
  );

  return (
    <>
      <>
        {isAuthenticate() && userDetails.get["status"] == "incomplete" && (
          <div
            className={`${
              window.location.pathname.includes(`/search`) ? "srchPdg" : ""
            } usrRegCheck`}
          >
            <p>
              Your account is incomplete, please{" "}
              <a
                onClick={() =>
                  handleUpdateClick(
                    userDetails.get["id"],
                    userDetails.get["email"],
                  )
                }
              >
                click here
              </a>{" "}
              to update your account info
            </p>
          </div>
        )}
      </>
      {isPropertyRemove ? (
        <NotFound />
      ) : (
        <Layout>
          {width ? <div></div> : <></>}

          <div className="pv-wrapper">
            <div className="container">
              <div className="ac-wrapper">
                <div className="property-left">
                  {width ? (
                    <>
                      <div className="row btmStckyHdr">
                        <div className="col-lg-12">
                          <div className="d-flex justify-content-between">
                            {getProductDetails.listing_format == "auction" && (
                              <div>
                                <a
                                  className="btn btn-primary"
                                  onClick={submittingBid}
                                >
                                  {userLoggedIn.get
                                    ? userDetails.get["id"] ==
                                      getProductDetails.user_id
                                      ? "Can't bid on own property"
                                      : timer.isCompleted === false &&
                                          timer.text === "Bid Due In" &&
                                          rtbCompleted &&
                                          timer.text !== "Bidding starts" &&
                                          timer.isCompleted === false &&
                                          highestBidder &&
                                          !!Object.keys(userDetails.get)
                                            .length &&
                                          userDetails.get["id"] == highestBidder
                                        ? "You are High Bidder"
                                        : timer.isCompleted === false &&
                                            timer.text === "Bid Due In" &&
                                            rtbCompleted &&
                                            timer.text !== "Bidding starts" &&
                                            timer.isCompleted === false
                                          ? "Submit a Bid"
                                          : (timer.isCompleted === false &&
                                                timer.text === "Bid Due In" &&
                                                !rtbCompleted) ||
                                              (timer.isCompleted === false &&
                                                timer.text ===
                                                  "Bidding starts" &&
                                                !rtbCompleted)
                                            ? "Register to Bid"
                                            : timer.isCompleted === false &&
                                                timer.text ===
                                                  "Bidding starts" &&
                                                rtbCompleted
                                              ? "Edit Registration"
                                              : "Bidding Closed"
                                    : !userLoggedIn.get &&
                                        timer.isCompleted === false
                                      ? "Register to Bid"
                                      : !userLoggedIn.get && timer.isCompleted
                                        ? "Bidding Closed"
                                        : null}
                                </a>
                              </div>
                            )}
                          </div>
                          {console.log("beforepopup", beforePopup)}
                        </div>
                      </div>

                      <GroupAuctionLeft
                        data={selectedGroupPropertyDetails}
                        propertyDetails={getProductDetails}
                        bidHistory={comments}
                        lable={lable}
                        bookTour={bookTour}
                        width={width}
                        constructAddressComponent={constructAddressComponent}
                        setBeforePopup={setBeforePopup}
                      />
                      {getProductDetails && getProductDetails.auction === 1 && (
                        <PropertyBids
                          nextBidAmount={nextBidAmount}
                          data={getProductDetails}
                          myClientsList={myClientsList}
                          myAgent={myAgent}
                          timer={timer}
                          setNextBidAmount={setNextBidAmount}
                          bidContainer={bidContainer}
                          seyProductDetails={seyProductDetails}
                          isMobile={false}
                          setModelopen={setModelopenBid}
                          modalopen={modalopenBid}
                          submittingBid={submittingBid}
                          highestBidder={highestBidder}
                        />
                      )}
                      {/* <PropertyComments
                        comments={comments}
                        noComments={noComments}
                        setComments={setComments}
                        handleCommentEdit={(edi) => setModalOpen({ ...edi })}
                        handleCommentDelete={OnDelete}
                        propertyDetails={getProductDetails}
                        agentDetails={agentDetails}
                        timer={timer}
                        width={width}
                        commentsRef={commentsRef}
                        setBeforePopup={setBeforePopup}
                        setSubmittedPopup={setSubmittedPopup}
                        submittedPopup={submittedPopup}
                      /> */}
                    </>
                  ) : (
                    <>
                      {getProductDetails && (
                        <div
                          className="mblBdngInfo"
                          style={{ paddingTop: `${heightChange + 40}px` }}
                        >
                          <GroupAuctionRight
                            userValue={userDetails}
                            highestBidder={highestBidder}
                            data={selectedGroupPropertyDetails}
                            propertyDetails={getProductDetails}
                            tourCount={tourCount}
                            agentDetails={agentDetails}
                            userDetails={getSellerData}
                            note={notes}
                            agentInfo={agentInfo}
                            openFile={openFile}
                            propertyId={id}
                            refreshNotes={() => getNotes()}
                            buyTimerNw={buyTimer}
                            timerNw={timer}
                            offerTimer={offerTimer}
                            // toggleModalBid={toggleModalBid}
                            submittingBid={submittingBid}
                            addProductToCart={addProductToCart}
                            // rtbFormConfirmation={rtbFormConfirmation}
                            rtbCompleted={rtbCompleted}
                            // rtbFiles={rtbFiles}
                            sellerTermsCon={sellerTermsCon}
                            width={width}
                            noComments={noComments}
                            addFav={addFav}
                            mobile={mobile}
                            fav={fav}
                            toggleShare={toggleShare}
                            clickSchedule={clickSchedule}
                            clickAskQuestion={clickAskQuestion}
                            commentsRef={commentsRef}
                            addTourCount={addTourCount}
                            clickBidNumber={clickBidNumber}
                            clickPropertyTable={clickPropertyTable}
                            submittingBuy={submittingBuy}
                            submittingOffer={submittingOffer}
                            soldDetails={soldDetails}
                            rtbFiles={rtbFiles}
                            setBeforePopup={setBeforePopup}
                            setSubmittedPopup={setSubmittedPopup}
                            submittedPopup={submittedPopup}
                            currentPropCity={currentPropCity}
                            groupPropertyDetails={groupPropertyDetails}
                          />
                        </div>
                      )}
                      <PropertyLeftTopMobile
                        data={getProductDetails}
                        matterport={getMatterPort}
                        showMatterport={showMatterport}
                        currentPropCity={currentPropCity}
                        lable={lable}
                        agentDetails={agentDetails}
                        userDetails={getSellerData}
                        note={notes}
                        openFile={openFile}
                        propertyId={id}
                        refreshNotes={() => getNotes()}
                        width={width}
                        nextBidAmount={nextBidAmount}
                        timer={timer}
                        myClientsList={myClientsList}
                        myAgent={myAgent}
                        setNextBidAmount={setNextBidAmount}
                        bidContainer={bidContainer}
                        seyProductDetails={seyProductDetails}
                        comments={comments}
                        noComments={noComments}
                        setComments={setComments}
                        handleCommentEdit={(edi) => setModalOpen({ ...edi })}
                        handleCommentDelete={OnDelete}
                        agentInfo={agentInfo}
                        sellerTermsCon={sellerTermsCon}
                        toggleShare={toggleShare}
                        setModelopen={setModelopenBid}
                        modalopen={modalopenBid}
                        submittingBid={submittingBid}
                        addFav={addFav}
                        mobile={mobile}
                        fav={fav}
                        bookTour={bookTour}
                        clickSchedule={clickSchedule}
                        clickAskQuestion={clickAskQuestion}
                        commentsRef={commentsRef}
                        addTourCount={addTourCount}
                        clickBidNumber={clickBidNumber}
                        tourCount={tourCount}
                        highestBidder={highestBidder}
                        heightChange={heightChange}
                        setHeightChange={setHeightChange}
                        setBeforePopup={setBeforePopup}
                        setDocUri={setDocUri}
                        setSubmittedPopup={setSubmittedPopup}
                        submittedPopup={submittedPopup}
                        bidHistory={comments}
                      />
                    </>
                  )}

                  {/* <MLSinformation /> */}
                  {/* {similarProperty.length !== 0 && (
                <SimilarHomes similarProperty={similarProperty} />
              )} */}
                </div>
                {width && getProductDetails && (
                  <>
                    <GroupAuctionRight
                      userValue={userDetails}
                      highestBidder={highestBidder}
                      data={selectedGroupPropertyDetails}
                      propertyDetails={getProductDetails}
                      tourCount={tourCount}
                      agentDetails={agentDetails}
                      userDetails={getSellerData}
                      note={notes}
                      agentInfo={agentInfo}
                      openFile={openFile}
                      propertyId={id}
                      refreshNotes={() => getNotes()}
                      buyTimerNw={buyTimer}
                      timerNw={timer}
                      offerTimer={offerTimer}
                      // toggleModalBid={toggleModalBid}
                      submittingBid={submittingBid}
                      addProductToCart={addProductToCart}
                      // rtbFormConfirmation={rtbFormConfirmation}
                      rtbCompleted={rtbCompleted}
                      // rtbFiles={rtbFiles}
                      sellerTermsCon={sellerTermsCon}
                      width={width}
                      noComments={noComments}
                      addFav={addFav}
                      mobile={mobile}
                      fav={fav}
                      toggleShare={toggleShare}
                      clickSchedule={clickSchedule}
                      clickAskQuestion={clickAskQuestion}
                      commentsRef={commentsRef}
                      addTourCount={addTourCount}
                      clickBidNumber={clickBidNumber}
                      clickPropertyTable={clickPropertyTable}
                      submittingBuy={submittingBuy}
                      submittingOffer={submittingOffer}
                      soldDetails={soldDetails}
                      rtbFiles={rtbFiles}
                      setBeforePopup={setBeforePopup}
                      setSubmittedPopup={setSubmittedPopup}
                      submittedPopup={submittedPopup}
                      currentPropCity={currentPropCity}
                      groupPropertyDetails={groupPropertyDetails}
                    />
                  </>
                )}
              </div>
            </div>
            <RuumrModal open={shareFollow} handleClose={toggleShare}>
              <div>
                <h5>Share Using</h5>
                <div className="d-flex align-items-center flex-wrap justify-content-center mt-4">
                  <Button className="shrBtn">
                    <FacebookShareButton
                      url={window.location.href}
                      imageURL={`${window.location.origin}/images/doorseylogoLarge.png`}
                      quote={getProductDetails.title}
                    >
                      <i className="shrIcon fab fa-facebook">
                        <br />
                        <small>Facebook</small>
                      </i>
                    </FacebookShareButton>
                  </Button>
                  <Button className="shrBtn">
                    <LinkedinShareButton url={window.location.href}>
                      <i className="shrIcon fab fa-linkedin">
                        <br />
                        <small>Linked In</small>
                      </i>
                    </LinkedinShareButton>
                  </Button>
                  <Button className="shrBtn">
                    <TwitterShareButton
                      url={window.location.href}
                      title={getProductDetails.title}
                      className="shrBtn"
                    >
                      <i className="shrIcon fab fa-twitter">
                        <br />
                        <small>Twitter</small>
                      </i>
                    </TwitterShareButton>
                  </Button>
                  <Button className="shrBtn" onClick={copyToClipBoard}>
                    <i className="shrIcon fas fa-clipboard">
                      <br />
                      <small>Copy to clipboard</small>
                    </i>
                  </Button>
                </div>
              </div>
            </RuumrModal>
            <RuumrModal open={follow} handleClose={toggleFollow}>
              <div className="follow-wrap">
                <p className="follow-note">
                  {step === 1
                    ? "Want to receive text about home?"
                    : "Receive the most up to date notifications on followed homes."}
                </p>
                {step === 1 ? (
                  <div className="">
                    <button
                      className="btn btn-primary"
                      onClick={() => setStep(2)}
                    >
                      Yes
                    </button>
                    <button
                      className="btn btn-primary"
                      onClick={() => addFav({ project_id: id })}
                    >
                      No
                    </button>
                  </div>
                ) : (
                  <div className="notifications-wrap">
                    <CustomInput
                      label="Phone Number"
                      name="phone"
                      onChange={favouriteAdd}
                      onBlur=""
                      className="hh-input"
                      placeholder="XXX-XXX-XXXX"
                      required
                    />
                    {mobileNumberVal ? (
                      <p className="invalid-feedback">
                        Phone number is invalid
                      </p>
                    ) : null}
                    <button
                      className="btn btn-primary"
                      onClick={addMobileNumberFollow}
                    >
                      Go
                    </button>
                  </div>
                )}
              </div>
            </RuumrModal>
            <Dialog
              editComment
              open={Object.keys(modalopen).length !== 0}
              onClose={(commentId, text, setTexts) => {
                onEdit(commentId, text);
                setTexts("");
                setModalOpen({});
              }}
              close={(e) => setModalOpen({})}
              edit={modalopen}
            />
            <CustomDialog
              dialogClass="beforePopup"
              // closeBtnText={
              //   <>
              //     <span
              //       className="clsButtonText"
              //       onClick={() => setBeforePopup(false)}
              //     >
              //       <u>Back</u>
              //     </span>
              //   </>
              // }
              open={
                beforePopup == "home_inspection" ||
                beforePopup == "bidding_update" ||
                beforePopup == "schedule_tour" ||
                beforePopup == "seller_question" ||
                beforePopup == "view_docs" ||
                beforePopup == "ask_question"
                  ? true
                  : false
              }
              handleClose={() => setBeforePopup(false)}
            >
              <div className="buyOfferPopupWrpr">
                {beforePopup == "home_inspection" ? (
                  <>
                    <div className="topTitlecontnr">
                      <h4>View home inspection</h4>
                    </div>
                  </>
                ) : beforePopup == "schedule_tour" ? (
                  <>
                    <div className="topTitlecontnr">
                      <h4>Schedule tour</h4>
                    </div>
                  </>
                ) : beforePopup == "bidding_update" ? (
                  <>
                    <div className="topTitlecontnr">
                      <h4>Get bidding updates</h4>
                    </div>
                  </>
                ) : beforePopup == "view_docs" ? (
                  <>
                    <div className="topTitlecontnr">
                      <h4>View Documents</h4>
                    </div>
                  </>
                ) : beforePopup == "ask_question" ? (
                  <>
                    <div className="topTitlecontnr">
                      <h4>Ask a question</h4>
                    </div>
                  </>
                ) : beforePopup == "seller_question" ? (
                  <>
                    <div className="topTitlecontnr">
                      <h4>Ask about terms</h4>
                    </div>
                  </>
                ) : (
                  ""
                )}
                <div className="mdlBodCntnr">
                  <p className="mdlIfoTxt">
                    Please add your email to access exclusive listing content
                    and features.
                  </p>
                </div>
                <div className="modlFormWrpr">
                  <form
                    className="mdlForm"
                    onSubmit={beforeFormik.handleSubmit}
                  >
                    <div className="row">
                      <div className="col-md-12 col-12 mt-3">
                        <CustomInput
                          label="Email*"
                          placeholder="Enter valid email address"
                          size="small"
                          type="email"
                          name="email"
                          className={`hh-input ${
                            beforeFormik.errors.email &&
                            beforeFormik.touched.email &&
                            "is-invalid"
                          }`}
                          value={beforeFormik.values.email}
                          onChange={beforeFormik.handleChange}
                          onBlur={beforeFormik.handleBlur}
                        />
                        <div className="d-flex align-items-center justify-content-between">
                          <>
                            {beforeFormik.errors.email &&
                            beforeFormik.touched.email ? (
                              <div className="invalid-feedback">
                                {beforeFormik.errors.email}
                              </div>
                            ) : null}
                          </>
                          {beforeFormik.values.email != "" &&
                          !beforeFormik.errors.email &&
                          validMessage?.message != "" ? (
                            <>
                              <div
                                className={`vldInvldMsg ${
                                  validMessage.type == "success"
                                    ? "text-success"
                                    : "text-danger"
                                }`}
                              >
                                {validMessage?.message}{" "}
                                {validMessage.type == "error" && (
                                  <>
                                    <a
                                      className="lgnLmk"
                                      onClick={() => handleExistingEmail()}
                                    >
                                      Login here
                                    </a>
                                  </>
                                )}
                              </div>
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div className="col-md-12 col-12 mt-3">
                        <div className="agnBxWrpr">
                          <label>Are you an agent?</label>
                          <div className="chkBxWp">
                            <CheckBox
                              label="Yes"
                              value="true"
                              checked={beforeFormik.values.isAgent === "true"}
                              onchange={(e) =>
                                beforeFormik.setFieldValue("isAgent", "true")
                              }
                            />
                            <CheckBox
                              label="No"
                              value="false"
                              checked={beforeFormik.values.isAgent === "false"}
                              onchange={(e) =>
                                beforeFormik.setFieldValue("isAgent", "false")
                              }
                            />
                          </div>
                        </div>
                        {beforeFormik.errors.isAgent &&
                        beforeFormik.touched.isAgent ? (
                          <div className="invalid-feedback">
                            {beforeFormik.errors.isAgent}
                          </div>
                        ) : null}
                      </div>
                      {beforeFormik?.values?.isAgent === "false" && (
                        <div className="col-md-12 col-12 mt-3">
                          <div className="agnBxWrpr">
                            <label>
                              Do you need an agent to help you buy a house?
                            </label>
                            <div className="chkBxWp">
                              <CheckBox
                                label="Yes"
                                value="true"
                                checked={
                                  beforeFormik.values.need_agent === "true"
                                }
                                onchange={(e) =>
                                  beforeFormik.setFieldValue(
                                    "need_agent",
                                    "true",
                                  )
                                }
                              />
                              <CheckBox
                                label="No"
                                value="false"
                                checked={
                                  beforeFormik.values.need_agent === "false"
                                }
                                onchange={(e) =>
                                  beforeFormik.setFieldValue(
                                    "need_agent",
                                    "false",
                                  )
                                }
                              />
                            </div>
                          </div>
                          {beforeFormik.errors.need_agent &&
                          beforeFormik.touched.need_agent ? (
                            <div className="invalid-feedback">
                              {beforeFormik.errors.need_agent}
                            </div>
                          ) : null}
                        </div>
                      )}
                    </div>
                    <PrimaryButton
                      disabled={validMessage?.type != "error" ? false : true}
                      label={
                        beforePopup == "home_inspection"
                          ? "View home inspection"
                          : beforePopup == "schedule_tour"
                            ? "Schedule tour"
                            : beforePopup == "bidding_update"
                              ? "Get Bidding Updates"
                              : beforePopup == "view_docs"
                                ? "View document"
                                : beforePopup == "ask_question"
                                  ? "Submit question"
                                  : beforePopup == "seller_question"
                                    ? "Ask about terms"
                                    : ""
                      }
                      type="submit"
                    />
                  </form>
                </div>
              </div>
            </CustomDialog>
          </div>
        </Layout>
      )}
    </>
  );
};

export default GroupAuctionView;
