import React, { useState, useEffect, isValidElement } from "react";
import {
  useLocation,
  useHistory,
  BrowserRouter,
  useParams,
} from "react-router-dom";
import "./App.css";
import Routes from "./Routes/Index";
import { BrowserRouter as Router } from "react-router-dom";
import ScrollToTop from "./Routes/scrollToTop";
import isAuthenticate from "./utils/auth";
import GlobalContext from "./context/GlobalContext";
import {
  getUserProfileData,
  logout,
  getAllReview,
  getUserInviteSent,
  getUserInviteReceived,
} from "./API/userData";
import axios from "axios";
import socket from "./utils/socket";
import axiosInstance from "./utils/axiosconfig";
import { chunk } from "lodash";
import { ToastContainer } from "react-toastify";
import { getStores } from "./API/stores";

global.defaultLocation = process.env.REACT_APP_DEFAULT_LOCATION;
global.image_url = process.env.REACT_APP_IMAGE_URL;

let allSite = [
  { site: "app.doorsey.com", storeID: 1 },
  // { site: "revolauctions.com", storeID: 2 },
  { site: "revolbids.com", storeID: 2 },
  { site: "excelhomeauctions.com", storeID: 3 },
  { site: "royallepage.ecommerce.auction", storeID: 6 },
];

function App() {
  const [online, isOnline] = useState(navigator.onLine);
  const [socketDisconnect, setSocketDisconnect] = useState(false);
  const [bidInc, setBidInc] = useState({});
  const [isAuth, setAuth] = useState(isAuthenticate() !== undefined);
  const [isLoading, setLoading] = useState(false);
  const [user, setUser] = useState({});
  const [location, setLocation] = useState({});
  const [time, setTime] = useState("");
  const [bids, setBids] = useState({});
  const [comments, setComments] = useState({});
  const [getRecentProperty, setResentProperty] = useState([]);
  const [award, setAward] = useState({});
  const [allReview, setAllReview] = useState([]);
  const [storeID, setStoreID] = useState(null);
  const [subPath, setSubPath] = useState("");
  const [liveSite, setLiveSite] = useState(false);
  const [headerSelectedLocation, setHeaderSelectedLocation] = useState({
    id: "",
    cities: "",
    state: "",
    lat: "",
    lng: "",
  });
  const [propertyTime, setPropertyTime] = useState({});
  const [completedVerifications, setCompletedVerifications] = useState({
    userConnection: false,
    financeInformation: false,
    billingInformation: false,
  });
  const [agentVerificationStatus, setAgentVerificationStatus] = useState({
    accountVerification: false,
    clientConnetion: false,
  });
  const [userConnection, setUserConnection] = useState(false);
  const [agentAccountVerification, setAgentAccountVerification] =
    useState(false);
  const [financeInfo, setFinanceInfo] = useState(false);
  const [billingInfo, setBillingInfo] = useState(false);
  const [invitesSent, setInvitesSent] = useState([]);
  const [invitesReceived, setInvitesReceived] = useState([]);
  const [financeInformation, setFinanceInformation] = useState({});
  const [billingInformation, setBillingInformaction] = useState([]);
  const [agentDetails, setAgentDetails] = useState({});
  const [socketLocation, setSocketLocation] = useState({});
  const [soketDisconnect, setSokcetDisconnect] = useState(false);
  const [storeData, setStoreData] = useState([]);
  const history = useHistory();
  // console.log(window, "thius is windoqw");
  const store = {
    userLoggedIn: { get: isAuth, set: setAuth },
    loading: { get: isLoading, set: setLoading },
    userDetails: { get: user, set: setUser },
    userLocation: { get: location, set: setLocation },
    currentTime: { get: time, set: setTime },
    bid: { get: bids, set: setBids },
    comment: { get: comments, set: setComments },
    resentProperty: { get: getRecentProperty, set: setResentProperty },
    propertyAwarded: { get: award, set: setAward },
    review: { get: allReview, set: setAllReview },
    headerLocation: {
      get: headerSelectedLocation,
      set: setHeaderSelectedLocation,
    },
    verificationStatus: {
      get: completedVerifications,
      set: setCompletedVerifications,
    },
    sentInvites: { get: invitesSent, set: setInvitesSent },
    receivedInvites: { get: invitesReceived, set: setInvitesReceived },
    financeDetails: { get: financeInformation, set: setFinanceInformation },
    billingDetails: { get: billingInformation, set: setBillingInformaction },
    agentVerification: {
      get: agentVerificationStatus,
      set: setAgentVerificationStatus,
    },
    agentVerificationDetails: { get: agentDetails, set: setAgentDetails },
    mapRadius: { get: socketLocation, set: setSocketLocation },
    bidIncrement: { get: bidInc, set: setBidInc },
    storeDetails: storeData,
  };
  const changeTheme = (stores) => {
    return document.documentElement.style.setProperty(
      "--primColor",
      stores?.store.primary_color,
    );
  };

  useEffect(() => {
    if (subPath) {
      setStoreID(parseInt(subPath, 10));
    }
  }, [subPath]);

  useEffect(() => {
    let windowURL = window.location.href.split("/");
    let url = windowURL[2];
    console.log("site here ", url, process.env.REACT_APP_URL);
    if (
      url === "doorseych.ecommerce.auction" ||
      url === "localhost:3000" ||
      url === "192.168.150.222:3000" ||
      url === "192.168.20.109:3000" ||
      url === "192.168.150.232:3000" ||
      url === process.env.REACT_APP_URL
    ) {
      const store_id = window.location.pathname.split("/")[1];
      console.log("I am TEST Site ", store_id);
      setStoreID(parseInt(store_id, 10));
      setLiveSite(false);
    } else {
      let obj = allSite.find((o) => o.site === url);
      console.log("I am LIVE Site ", obj);
      if (obj) {
        setStoreID(parseInt(obj.storeID, 10));
        setLiveSite(true);
      }
    }
  }, []);

  useEffect(async () => {
    if (storeID > 0) {
      let stores = await getStores(storeID);
      localStorage.removeItem("site_id");
      localStorage.removeItem("store_id");
      if (stores.status === true) {
        changeTheme(stores);
        setStoreData(stores);
        localStorage.setItem("site_id", stores?.store.site_id);
        localStorage.setItem("store_id", stores?.store.id);
      }
    }
  }, [storeID]);

  const site_id = localStorage.getItem("site_id");

  useEffect(() => {
    socket.on("connect", () => {
      setSocketDisconnect(false);
    });
    socket.on("sliservertime", (data) => setTime(data.dTime));
    socket.on("bidAddtime", (data) => {
      console.log(data, "bids data");
      setBids(data);
    });
    socket.on("comments", (data) => setComments(data));
    socket.on("awardedProperty", (data) => {
      setAward(data);
    });
    socket.on("location", (data) => {
      // console.log("locationsocketvalue", data);
      setSocketLocation(data);
    });
    socket.on("bidIncrement", (data) => {
      setBidInc(data);
    });
    socket.on("disconnect", () => {
      // console.log("socket is disconnected from apps jsds");
      setSocketDisconnect(true);
    });

    // getLocation();
    if (site_id != (null || undefined)) {
      getReview(site_id);
    }

    window.deviceType = getDeviceType();
  }, []);

  window.addEventListener("focus", () => {
    // console.log("Connecting to socket Focus", socket.connected);
    if (!socket.connected) {
      socket.connect();
    }
  });

  useEffect(() => {
    window.addEventListener("online", () => {
      setSocketDisconnect(false);
    });

    window.addEventListener("offline", () => {
      setSocketDisconnect(true);
    });
  }, [socketDisconnect]);

  // Register the event listeners
  // window.addEventListener("offline", setOffline);
  // window.addEventListener("online", setOnline);

  // // cleanup if we unmount
  // return () => {
  //   window.removeEventListener("offline", setOffline);
  //   window.removeEventListener("online", setOnline);
  // };

  useEffect(() => {
    if (process.env.REACT_APP_AUTO_REFRESH === "ON") {
      let visibilityHidden, visibilityChange;
      // Opera 12.10 and Firefox 18 and later support
      if (typeof document.hidden !== "undefined") {
        visibilityHidden = "hidden";
        visibilityChange = "visibilitychange";
      } else if (typeof document.mozHidden !== "undefined") {
        visibilityHidden = "mozHidden";
        visibilityChange = "mozvisibilitychange";
      } else if (typeof document.msHidden !== "undefined") {
        visibilityHidden = "msHidden";
        visibilityChange = "msvisibilitychange";
      } else if (typeof document.webkitHidden !== "undefined") {
        visibilityHidden = "webkitHidden";
        visibilityChange = "webkitvisibilitychange";
      }
      document.addEventListener("visibilitychange", function () {
        if (
          !document[visibilityHidden] &&
          window.location.href.includes("/property")
        ) {
          //console.log("Page is hidden from user view");
          window.location.reload(true);
        } else {
          //console.log("Page is in user view");
        }
      });
    }
  }, [
    document.hidden,
    document.mozHidden,
    document.msHidden,
    document.webkitHidden,
  ]);

  useEffect(() => {
    if (isAuthenticate()) {
      getUserProfile();
    }
  }, [isAuthenticate(), site_id]);
  const getDeviceType = () => {
    const ua = navigator.userAgent;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
      return "tablet";
    }
    if (
      /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
        ua,
      )
    ) {
      return "mobile";
    }
    return "desktop";
  };
  const setOnline = () => {
    console.log("online");
    // alert("online");
    isOnline(true);
    window.location.reload(true);
  };
  const setOffline = () => {
    console.log("offline");
    // alert("offline");
    window.isOnline(false);
  };

  const getReview = async (siteid) => {
    try {
      let data = await getAllReview(siteid);
      if (Array.isArray(data)) {
        setAllReview(chunk(data, 3));
      }
    } catch (err) {
      console.log(err, "=>err");
    }
  };

  const getLocation = async () => {
    let { data } = await axios.get(process.env.REACT_APP_GEO_LOCATION);
    if (data.status === "success") {
      setLocation({
        city: data.city,
        lat: data.lat,
        lon: data.lon,
        currentLat: data.lat,
        currentLon: data.lon,
        state: data.regionName,
        countryCode: data.countryCode,
        ipAddress: data.query,
      });
    }
    // let searchData = await getSearch({
    //   cities: [data.city],
    //   page: 1,
    //   limit: 3,
    //   status: "",
    // });
    // searchData.status === true &&
    //   setResentProperty(searchData.result.productdetails);
  };

  const getUserProfile = async () => {
    if (site_id != (null || undefined)) {
      let data = await getUserProfileData(site_id);
      let inviteSent = await getUserInviteSent(site_id);
      let inviteRecieved = await getUserInviteReceived(site_id);
      if (data) {
        setUser({
          email: data.userDetails.email,
          first_name: data.userDetails.first_name,
          role: data.userDetails.role,
          profiletype: data.userDetails.profiletype,
          last_name: data.userDetails.last_name,
          user_name: data.userDetails.username,
          status: data.userDetails.status,
          id: data.userDetails.id,
          phone: data.userDetails.phone,
          address: data.userDetails.address1,
          companyName: data.userDetails.companyName,
          serviceType: data.userDetails.serviceType,
          created: data.userDetails.created_at,
          aboutme: data.userDetails.aboutme,
          avatar: data.userDetails.avatar,
          limit: data.userDetails.reserve_amount,
          customerid: data.userDetails.customerid,
          agent_exempt_request: data.userDetails.agent_exempt_request,
          finance_exempt_request: data.userDetails.finance_exempt_request,
          card_name: data.userDetails.card_name,
          myAgent: data.my_agent,
          card_date: data.userDetails.card_updated,
          //agent_approved: data.userDetails.agent_approved,
          bid_approval: data.userDetails.bid_approval,
        });

        setInvitesSent(inviteSent);
        setInvitesReceived(inviteRecieved);
        if (data.userDetails.fin_doc && data.userDetails.reserve_amount) {
          setFinanceInformation({
            financeDocuments: [
              data.userDetails.fin_doc,
              data.userDetails.fin_doc2,
              data.userDetails.fin_doc3,
              data.userDetails.fin_doc4,
              data.userDetails.fin_doc5,
            ],
            purchasePower: data.userDetails.reserve_amount,
            lenderEmail: data.userDetails.lender_email,
            financeType: data.userDetails.fin_type,
          });
        }
        if (data.cardDetails.length > 0) {
          setBillingInformaction(data.cardDetails);
        }
        if (
          data.userDetails.profiletype === "agent" &&
          data.userDetails.companyName
        ) {
          setAgentDetails({
            brokerageOffice: data.userDetails.companyName,
            brokerageOfficeUrl: data.userDetails.brokerage_url,
            phone: data.userDetails.phone,
          });
        }
      } else {
        localStorage.removeItem("userToken");
        // setAuth(false);
        // setUser({});
        clearGlobalVariables();
        logout();
      }
    }
  };

  const clearGlobalVariables = () => {
    setAuth(false);
    setUser({});
    setInvitesSent([]);
    setInvitesReceived([]);
    setFinanceInformation({});
    setBillingInformaction([]);
    setAgentDetails({});
  };

  useEffect(() => {
    setAgentVerificationStatus({
      accountVerification: agentAccountVerification,
      clientConnetion: userConnection,
    });
  }, [userConnection, agentAccountVerification]);

  useEffect(() => {
    setCompletedVerifications({
      userConnection: userConnection,
      financeInformation: financeInfo,
      billingInformation: billingInfo,
    });
  }, [userConnection, financeInfo, billingInfo]);
  // useEffect(() => {
  //   if (
  //     locationPath &&
  //     locationPath.pathname &&
  //     locationPath.pathname === "/upcoming"
  //   ) {
  //     getLocation();
  //   }
  // }, [locationPath]);
  useEffect(() => {
    if (invitesSent.length > 0 || invitesReceived.length > 0) {
      setUserConnection(true);
    } else if (
      invitesSent.length === 0 &&
      invitesReceived.length === 0 &&
      !user.agent_exempt_request
    ) {
      setUserConnection(false);
    }
  }, [invitesSent, invitesReceived]);

  useEffect(() => {
    if (Object.keys(financeInformation).length > 0) {
      setFinanceInfo(true);
    } else if (!user.finance_exempt_request) {
      setFinanceInfo(false);
    }
  }, [financeInformation]);

  useEffect(() => {
    if (billingInformation.length > 0) {
      setBillingInfo(true);
    } else {
      setBillingInfo(false);
    }
  }, [billingInformation]);

  useEffect(() => {
    if (user) {
      if (user.finance_exempt_request) {
        setFinanceInfo(true);
      }
      if (user.agent_exempt_request) {
        setUserConnection(true);
      }
      if (user.bid_approval) {
        setAgentAccountVerification(true);
      }
    }
  }, [user]);

  return (
    <React.StrictMode>
      <div className="App">
        {socketDisconnect && (
          <p className="network-issue">
            Network has been disconnected trying to reconnect... or click here
            to{" "}
            <span
              onClick={() => window.location.reload(0)}
              className="network-reload"
            >
              reload
            </span>
          </p>
        )}
        <ToastContainer pauseOnFocusLoss={false} />
        <GlobalContext.Provider value={store}>
          <Router>
            <ScrollToTop />
            <Routes />
          </Router>
        </GlobalContext.Provider>
      </div>
    </React.StrictMode>
  );
}

export default App;
