import React, { useState, useEffect, useContext, Fragment } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import moment from "moment";

import Layout from "../../../components/molecules/Layout";
import PrimaryButton from "../../../components/atoms/PrimaryButton";
import { getPropertyDetails } from "../../../API/propertyDetails";
import {
  showSuccess,
  showWaring,
  alertWithButton,
  onPropertyImageError,
} from "../../../utils";
import GlobalContext from "../../../context/GlobalContext";
import "./signup.css";

const RegisterToBidSuccess = (props) => {
  const history = useHistory();
  const location = useLocation();
  const { userDetails, userLocation } = useContext(GlobalContext);

  const [propData, setPropData] = useState({});

  /*const getPropertyData = async (id) => {
    let data = await getPropertyDetails(id);
    if (data.status === true) {
      setPropData(data.result.propertyDetails);
    }
  }*/

  console.log(props, "props.success");

  useEffect(() => {
    if (location && location.state) {
      console.log(location.state, "this is location state");
      setPropData(location.state.propertyDtls);
    }
  }, [location]);

  const constructAddressComponent = (
    address,
    zipcode,
    unitNumber,
    city,
    state,
  ) => {
    var addressArray = address?.split(",");
    if (addressArray?.length > 0) {
      if (!!unitNumber.length) {
        return (
          <>
            <div className="addressLine1">
              {`${addressArray[0]}, ${unitNumber}, ${addressArray[1]},`}
              <br />
              {` ${addressArray[2]?.trim().split(" ")[0]} ${zipcode || ""}`}
            </div>{" "}
            {/* <span className="addressLine2">{`${addressArray[1]}, ${
              addressArray[2]?.trim().split(" ")[0]
            } ${zipcode}`}</span> */}
          </>
        );
      } else if (addressArray?.length === 1) {
        return (
          <div className="addressLine1">
            {`${addressArray[0]}, `}
            <br />
            {` ${city}, ${state}, ${zipcode || ""}`}
          </div>
        );
      } else {
        return (
          <>
            <div className="addressLine1" style={{ width: "100%" }}>
              {`${addressArray[0]},`}
              <br />
              {`${addressArray[1]}, ${addressArray[2]?.trim().split(" ")[0]} ${
                zipcode || ""
              }`}
            </div>{" "}
            {/* <span className="addressLine2">{`${addressArray[1]}, ${
              addressArray[2]?.trim().split(" ")[0]
            } ${zipcode}`}</span> */}
          </>
        );
      }
    } else {
      return address;
    }
  };
  const store_id = window.location.pathname.split("/")[1];
  // const backToProperty = () => {
  //   if (location && location.state && !!Object.keys(location.state).length) {
  //     history.push({
  //       pathname: `/property/${propData?.id}`,
  //       state: {
  //         productType: location?.state?.productType,
  //         from: location?.state?.from,
  //       },
  //     });
  //   }
  // };

  const backToProperty = () => {
    if (location && location.state && !!Object.keys(location.state).length) {
      const isGroupAuction = props.location.state.propertyDtls.group_auction;
      const basePath = isGroupAuction ? "/auction-group" : "/property";

      history.push({
        pathname: `${basePath}/${propData?.id}`,
        state: {
          productType: location?.state?.productType,
          from: location?.state?.from,
        },
      });
    }
  };

  return (
    <Layout>
      {propData && !!Object.keys(propData).length && (
        <div className="container auth-container">
          <div className="auth-wrapper reg-bid-success-wrp">
            <p className="reg-bid-title">Register to Purchase</p>
            <div className="back-to-prop reg-bid-prop">
              <img
                className="prop-img"
                src={`${process.env.REACT_APP_IMAGE_URL}uploads/product/${propData.avatar}`}
                onError={(e) => onPropertyImageError(e)}
              />
              {propData.house_number
                ? constructAddressComponent(
                    propData.loc_address,
                    propData.zipcode,
                    propData.house_number,
                  )
                : constructAddressComponent(
                    propData.loc_address,
                    propData.zipcode,
                    "",
                    propData.loc_city,
                    propData.state,
                  )}
            </div>
            <div className="back-to-prop success-redir">
              <h3>Success!</h3>
              <p>
                Your are registered to bid on this listing. <br />
                When bidding begin on this property, submit bids in real <br />
                time and make it yours!
              </p>
              {/* <PrimaryButton
                onClick={backToProperty}
                label="Back To The Property Page"
              /> */}
              <PrimaryButton
                onClick={backToProperty}
                label={
                  props.location.state.propertyDtls.group_auction
                    ? "Back To The Auction Page"
                    : "Back To The Property Page"
                }
              />
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
};
export default RegisterToBidSuccess;
