import React, { useContext, useRef, useEffect, useState } from "react";
import { NavLink, Link, useHistory, useLocation } from "react-router-dom";
import MenuLinks from "../DashboardLayout/MenuLinks";
import GlobalContext from "../../../context/GlobalContext";
import { logout, getLocation } from "../../../API/userData";
import useToggle from "../../../utils/useToggle";
import useWidth from "../../../utils/useWidth";
import WebLinks from "./WebLinks";
import MobileLinks from "./MobileLinks";
import FormControl from "@material-ui/core/FormControl";
import { makeStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { stateCode } from "../../../utils";
import "./Header.css";
import isAuthenticate from "../../../utils/auth";

global.defaultLocation = process.env.REACT_APP_DEFAULT_LOCATION;
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: "293px",
    marginBottom: "10px",
    height: "45px",
  },
  mobileFormControl: {
    margin: theme.spacing(1),
    width: "163px",
    marginBottom: "5px",
  },
  formControldiv: {
    width: "300px",
  },
  mobileFormControldiv: {
    width: "170px",
    marginBottom: "3px",
  },
}));

const Header = ({ store_data }) => {
  const ref = useRef(null);
  const [width] = useWidth(ref);
  const [propertyLoc, setPropertyLoc] = useState([]);
  const location = useLocation();
  const history = useHistory();
  const classes = useStyles();
  const [reload, setReload] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState({
    id: "",
    // cities: "",
    // state: "",
    title: "",
    lat: "",
    lng: "",
  });
  const {
    userLoggedIn,
    userDetails,
    userLocation,
    headerLocation,
    mapRadius,
    storeDetails,
  } = useContext(GlobalContext);
  const [menu, toggleMenu] = useToggle(false);
  const site_id = localStorage.getItem("site_id");
  const store_id = window.location.pathname.split("/")[1];
  useEffect(() => {
    if (site_id != (null || undefined)) {
      propertyLocation();
    }
  }, [site_id]);

  useEffect(() => {
    let url = new URLSearchParams(location.search);
    if (site_id != (null || undefined) && url.get("load") == "true") {
      propertyLocation();
    }
  }, [location]);

  useEffect(() => {
    if (headerLocation.get) {
      setSelectedLocation(headerLocation.get);
    }
  }, [headerLocation.get]);

  useEffect(() => {
    if (mapRadius.get) {
      let newValue = mapRadius.get;
      let url = new URLSearchParams(location.search);
      let locIndex = propertyLoc.findIndex((e) => e.id == newValue.id);
      if (locIndex !== -1) {
        let newLocation = propertyLoc;
        newLocation[locIndex].radius = newValue.radius;
        newLocation[locIndex].title = newValue.title;
        newLocation[locIndex].lat = newValue.lat;
        newLocation[locIndex].long = newValue.lng;
        console.log(
          "locationsocketvalue title",
          propertyLoc,
          url,
          newValue,
          url.get("title"),
          locIndex,
          "selectedd location ----->",
          selectedLocation,
          "newLocation--->",
          newLocation,
        );
        setPropertyLoc(newLocation);
        // setReload(!reload);
        if (selectedLocation.id == newValue.id) {
          url.set("radius", newValue.radius);
          url.set("title", newValue.title);
          url.set("lat", newValue.lat);
          url.set("long", newValue.lng);
          history.replace({
            pathname: `/search`,
            search: "?" + url.toString(),
          });
        }
      }
    }
  }, [mapRadius.get]);

  useEffect(() => {
    if (location.pathname !== `/search` && headerLocation.get.id !== "") {
      headerLocation.set({
        id: "",
        title: "",
        // cities: "",
        // state: "",
        lat: "",
        lng: "",
      });
    }
  }, [location.pathname, headerLocation.get]);

  const propertyLocation = async () => {
    let url = new URLSearchParams(location.search);
    let data = await getLocation(site_id);
    // console.log(data, "what is data?");
    if (data && data.length > 0) {
      setPropertyLoc(data);
      if (url.get("load") === "true") {
        let defaultLocation = data[0];
        url.set("cities", "");
        // console.log("what is url 2: ", url);
        url.set("state", "");
        url.set("radius", defaultLocation.radius);
        // url.set("state", selectedValue[0].state);
        url.set("title", defaultLocation.title);
        // console.log("what is url 3: ", url);
        url.set("lat", defaultLocation.lat);
        // console.log("what is url 4: ", url);
        url.set("long", defaultLocation.lng);
        // console.log("what is url 5: ", url);
        url.set(
          "north",
          calculateNewLatLong(defaultLocation.lat, defaultLocation.radius, 0),
        );
        url.set(
          "south",
          calculateNewLatLong(defaultLocation.lat, defaultLocation.radius, 180),
        );
        url.set(
          "east",
          calculateNewLatLong(defaultLocation.lng, defaultLocation.radius, 90),
        );
        url.set(
          "west",
          calculateNewLatLong(defaultLocation.lng, defaultLocation.radius, 270),
        );
        url.set("load", false);
        history.replace({
          pathname: `/search`,
          search: "?" + url.toString(),
        });
      }
    }
  };

  const converttoRad = (num) => {
    return (Math.PI * num) / 180;
  };

  const converttoDeg = (num) => {
    return (num * 180) / Math.PI;
  };

  const calculateNewLatLong = (startingPoint, radiusMiles, bearing) => {
    //bearing for (n, e, s, w) = (0, 90, 180, 270)
    let numDegrees = Number(radiusMiles) / 69;

    if (bearing === 0) {
      return Number(startingPoint) + numDegrees;
    } else if (bearing === 90) {
      return Number(startingPoint) + numDegrees;
    } else if (bearing === 180) {
      return Number(startingPoint) - numDegrees;
    } else if (bearing === 270) {
      return Number(startingPoint) - numDegrees;
    }

    // let newRadiusMiles = (radiusMiles * 1.60934) / 6371;
    // let newBearing = converttoRad(bearing);

    // //conversion for north/south - long
    // if (bearing === 0 || bearing === 180) {
    //   let lon1 = converttoRad(startingPoint);
    //   let finalPoint = lon1 + Math.atan2(Math.sin(newBearing) * Math.sin(newRadiusMiles) *
    //   Math.cos(lat1),
    //   Math.cos(newRadiusMiles) - Math.sin(lat1) *
    //   Math.sin(lat2));
    // }
    // //conversion for east/west - lat
    // if (bearing === 90 || bearing === 270) {
    //   let lat1 = converttoRad(startingPoint);
    //   let finalPoint = Math.asin(Math.sin(lat1) * Math.cos(newRadiusMiles) +
    //   Math.cos(lat1) * Math.sin(newRadiusMiles) * Math.cos(newBearing));
    //   return finalPoint
    // }
  };

  useEffect(() => {
    if (
      window.location.pathname.includes("/listings") &&
      !window.location.hash
    ) {
      window.location = window.location + "#loaded";
      window.location.reload();
    }
  }, []);

  const handleLocationChng = (e) => {
    // console.log("what is e?  ", e);
    // eslint-disable-next-line array-callback-return
    // setReload(!reload);
    let url = new URLSearchParams(location.search);
    console.log("selectedValuenew---e", e);
    let selectedValue = propertyLoc.filter((ele) => {
      if (ele.id === Number(e.target.value)) {
        // console.log("is this envoked", ele);
        console.log(
          "selectedValuenew finding the current value",
          ele,
          propertyLoc,
        );
        return ele;
      }
      // console.log("is this envoked 2", ele);
    });
    console.log("selectedValuenew after setting the value", selectedValue);

    // console.log(selectedValue, "this is the selectedvalue--");

    // console.log("what is url 1: ", selectedValue[0]);
    url.set("cities", "");
    url.set("state", "");
    url.set("radius", selectedValue[0].radius);
    url.set("title", selectedValue[0].title);
    url.set("lat", selectedValue[0].lat);
    url.set("long", selectedValue[0].lng);
    url.set("page", 1);
    url.set(
      "north",
      calculateNewLatLong(selectedValue[0].lat, selectedValue[0].radius, 0),
    );
    url.set(
      "south",
      calculateNewLatLong(selectedValue[0].lat, selectedValue[0].radius, 180),
    );
    url.set(
      "east",
      calculateNewLatLong(selectedValue[0].lng, selectedValue[0].radius, 90),
    );
    url.set(
      "west",
      calculateNewLatLong(selectedValue[0].lng, selectedValue[0].radius, 270),
    );
    url.set("initial", true);
    url.set("load", false);
    url.set("zoom", false);
    console.log("zoom", url.get("zoom"));

    headerLocation.set(selectedValue);
    setSelectedLocation(selectedValue);
    console.log("selectedLocationnew--- final setting", selectedLocation);
    if (location.pathname.includes(`/search`)) {
      // console.log("what is url 6: ", url.toString());
      history.replace({
        pathname: location.pathname,
        search: "?" + url.toString(),
        state: {
          path: location.pathname,
        },
      });
    } else {
      // console.log("what is url 7: ", url);
      history.push({
        pathname: `/search`,
        // search: `?cities=${selectedValue[0].city}&state=${selectedValue[0].state}&page=1&sortby=1&status=past,accepting_offers,coming_soon&type=Single Family,Multi Family,Condominium / Townhome,Mobile / Manufactured&lat=${selectedValue[0].lat}&long=${selectedValue[0].lng}`,
        search: `?cities=&state=&title=${
          selectedValue[0].title
        }&page=1&sortby=1&status=coming_soon,for_sale,pending,sold&type=Single Family,Multi Family,Condominium / Townhome,Mobile / Manufactured&lat=${
          selectedValue[0].lat
        }&long=${selectedValue[0].lng}&radius=${
          selectedValue[0].radius
        }&north=${calculateNewLatLong(
          selectedValue[0].lat,
          selectedValue[0].radius,
          0,
        )}&south=${calculateNewLatLong(
          selectedValue[0].lat,
          selectedValue[0].radius,
          180,
        )}&east=${calculateNewLatLong(
          selectedValue[0].lng,
          selectedValue[0].radius,
          90,
        )}&west=${calculateNewLatLong(
          selectedValue[0].lng,
          selectedValue[0].radius,
          270,
        )}&initial=true&zoom=false&load=false`,
        state: {
          path: location.pathname,
        },
      });
    }
  };

  const handleUpdateClick = (id, email) => {
    history.push({
      pathname: `/signup`,
      state: {
        action: "userUpdate",
        user_id: id,
        values: {
          email: email,
        },
      },
    });
  };

  return (
    <>
      {console.log(store_data, "store_dataCheck")}
      <header ref={ref} className="header-wrap inner-header">
        <nav className="navbar navbar-expand-md" role="navigation">
          <div className="navbar-header d-none d-md-block">
            <Link className="navbar-brand" to={`/`}>
              <img
                className="img-responsive hm-logo"
                src={
                  store_data?.store?.id
                    ? `${process.env.REACT_APP_IMAGE_URL}uploads/site_logos/site_${store_data?.store?.id}/${store_data?.store?.logo}`
                    : "/images/doorseylogo.png"
                }
                alt="Doorsey"
              />
            </Link>
          </div>
          <div className="mblNwHdr d-sm-block d-md-none">
            <div className="navbar-header">
              <button
                type="button"
                className="navbar-toggler"
                data-toggle="collapse"
                data-target="#navbar-nav"
                aria-expanded="false"
                onClick={toggleMenu}
              >
                <i className="fa fa-bars"></i>
              </button>
              <a className="navbar-brand" href="https://www.doorsey.com">
                <img
                  className="img-responsive hm-logo"
                  src={
                    store_data?.store?.id
                      ? `${process.env.REACT_APP_IMAGE_URL}uploads/site_logos/site_${store_data?.store?.id}/${store_data?.store?.logo}`
                      : "/images/doorseylogo.png"
                  }
                  alt="Doorsey"
                />
              </a>
              <Link
                // to='/property/290'
                // to={`/search?cities=${userLocation.get["city"]}&page=1&sortby=1&status=accepting_offers,coming_soon`}

                to={`/search`}
                className="navbar-toggler-search d-md-none"
              >
                <i className="fa fa-search"></i>
              </Link>
            </div>
            {window.location.pathname.includes(`/search`) && (
              <ul className="navbar-nav mr-auto align-items-md-center">
                <li className="nav-item" style={{ padding: 0 }}>
                  <FormControl
                    variant="outlined"
                    className={
                      !width ? classes.mobileFormControl : classes.formControl
                    }
                  >
                    <Select
                      id="demo-simple-select-outlined"
                      value={
                        selectedLocation[0]?.id
                          ? selectedLocation[0]?.id
                          : selectedLocation.id
                      }
                      onChange={handleLocationChng}
                      displayEmpty
                      style={{ height: "45px" }}
                      inputProps={{ "aria-label": "Without label" }}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                      }}
                      className={classes.selectEmpty}
                    >
                      <MenuItem value="" disabled>
                        View Listings
                      </MenuItem>
                      {propertyLoc &&
                        propertyLoc.length > 0 &&
                        propertyLoc.map((ele) => (
                          <MenuItem
                            key={ele.id}
                            value={ele.id}
                            // disabled={ele.coming_soon === "true" ? true : false}
                          >
                            {ele.title}
                            {/* {ele.coming_soon === "true" ? (
                          <em>{`${ele.title} (Coming Soon)`}</em>
                        ) : (
                          `${ele.title}`
                        )} */}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>

                  {/* <div className="selectWrapper">
                <select
                  className="form-control wd-240"
                  value={headerLocation.get["id"]}
                  onChange={handleLocationChng}
                >
                  <option value="">Choose Location</option>
                  {propertyLoc &&
                    propertyLoc.length > 0 &&
                    propertyLoc.map((ele) => (
                      <option
                        key={ele.id}
                        value={ele.id}
                        disabled={ele.coming_soon === "true" ? true : false}
                      >
                        {ele.city},{stateCode(ele.state)}{" "}
                        {ele.coming_soon === "true" ? "(Coming Soon)" : null}
                      </option>
                    ))}
                </select>
              </div> */}
                </li>
              </ul>
            )}
          </div>
          <div
            className={`collapse navbar-collapse top-menu ${menu && "show"}`}
            id="navbar-nav"
          >
            <ul className="navbar-nav mr-auto align-items-md-center d-none d-md-block">
              <li className="nav-item" style={{ padding: 0 }}>
                {/* <FormControl
                  variant="outlined"
                  className={
                    !width ? classes.mobileFormControl : classes.formControl
                  }
                >
                  <Select
                    id="demo-simple-select-outlined"
                    value={
                      selectedLocation[0]?.id
                        ? selectedLocation[0]?.id
                        : selectedLocation.id
                    }
                    onChange={handleLocationChng}
                    displayEmpty
                    style={{ height: "45px" }}
                    inputProps={{ "aria-label": "Without label" }}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                    }}
                    className={classes.selectEmpty}
                  >
                    <MenuItem value="" disabled>
                      View Listings
                    </MenuItem>
                    {propertyLoc &&
                      propertyLoc.length > 0 &&
                      propertyLoc.map((ele) => (
                        <MenuItem key={ele.id} value={ele.id}>
                          {ele.title}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl> */}
                <FormControl
                  variant="outlined"
                  className={
                    !width ? classes.mobileFormControl : classes.formControl
                  }
                >
                  <Select
                    id="demo-simple-select-outlined"
                    value={
                      selectedLocation[0]?.id
                        ? selectedLocation[0]?.id
                        : selectedLocation.id
                    }
                    onChange={handleLocationChng}
                    displayEmpty
                    style={{ height: "45px" }}
                    inputProps={{ "aria-label": "Without label" }}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                    }}
                    className={classes.selectEmpty}
                  >
                    <MenuItem value="" disabled>
                      View Listings
                    </MenuItem>
                    {propertyLoc &&
                      propertyLoc
                        .slice() // Create a copy of the array to avoid mutating the original array
                        .sort((a, b) => a.title.localeCompare(b.title)) // Sort by title alphabetically
                        .map((ele) => (
                          <MenuItem key={ele.id} value={ele.id}>
                            {ele.title}
                          </MenuItem>
                        ))}
                  </Select>
                </FormControl>

                {/* <div className="selectWrapper">
                <select
                  className="form-control wd-240"
                  value={headerLocation.get["id"]}
                  onChange={handleLocationChng}
                >
                  <option value="">Choose Location</option>
                  {propertyLoc &&
                    propertyLoc.length > 0 &&
                    propertyLoc.map((ele) => (
                      <option
                        key={ele.id}
                        value={ele.id}
                        disabled={ele.coming_soon === "true" ? true : false}
                      >
                        {ele.city},{stateCode(ele.state)}{" "}
                        {ele.coming_soon === "true" ? "(Coming Soon)" : null}
                      </option>
                    ))}
                </select>
              </div> */}
              </li>
            </ul>
            {width >= 767 ? (
              <WebLinks width={width} store_data={store_data} />
            ) : (
              <MobileLinks store_data={store_data} />
            )}
          </div>
        </nav>
      </header>
      {!window.location.pathname.includes("/property") && (
        <>
          {isAuthenticate() && userDetails.get["status"] == "incomplete" && (
            <div
              className={`${
                window.location.pathname.includes(`/search`) ? "srchPdg" : ""
              } usrRegCheck`}
            >
              <p>
                Your account is incomplete, please{" "}
                <a
                  onClick={() =>
                    handleUpdateClick(
                      userDetails.get["id"],
                      userDetails.get["email"],
                    )
                  }
                >
                  click here
                </a>{" "}
                to update your account info
              </p>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default Header;
