import React, { useContext, useState, useEffect } from "react";
import { NavLink, Link, useHistory, useLocation } from "react-router-dom";
import MenuLinks from "../DashboardLayout/MenuLinks";
import GlobalContext from "../../../context/GlobalContext";
import { logout } from "../../../API/userData";
import "./Header.css";
import Badge from "@material-ui/core/Badge";
import { TramRounded } from "@material-ui/icons";

const WebLinks = ({ store_data, props }) => {
  const { storeDetails } = useContext(GlobalContext);
  const location = useLocation();
  const history = useHistory();
  const {
    userLoggedIn,
    userDetails,
    userLocation,
    agentVerification,
    sentInvites,
    receivedInvites,
    financeDetails,
    billingDetails,
    agentVerificationDetails,
    verificationStatus,
  } = useContext(GlobalContext);
  const [verificationCount, setVerificationCount] = useState(0);

  const calculateVerificationCount = () => {
    let count = 0;
    let notificationStatusPayload =
      userDetails.get["profiletype"] === "agent"
        ? agentVerification.get
        : userDetails.get["profiletype"] === "customer" ||
            userDetails.get["profiletype"] === "builder"
          ? verificationStatus.get
          : "";
    if (notificationStatusPayload) {
      for (const property in notificationStatusPayload) {
        if (!notificationStatusPayload[property]) {
          count += 1;
        }
      }
    }
    setVerificationCount(count);
  };

  const isActive = location.search.includes("group_auction=true");

  function refreshPage() {
    window.location.reload();
  }

  useEffect(() => {
    if (userDetails && userDetails.get) {
      calculateVerificationCount();
    }
  }, [verificationStatus.get, agentVerification.get, userDetails.get]);

  return (
    <ul className="navbar-nav ml-auto align-items-md-center nvOptns">
      {/* {userLoggedIn.get !== true && (
        <>
          <li className="nav-item">
            <a
              href="https://www.doorsey.com/community"
              activeClassName="active"
            >
              Community
            </a>
          </li>
          <li className="nav-item">
            <a
              href="https://www.doorsey.com/submit-a-home"
              activeClassName="active"
            >
              Sell
            </a>
          </li>
          <li className="nav-item">
            <a href="https://www.doorsey.com/buy" activeClassName="active">
              Buy
            </a>
          </li>
          <li className="nav-item">
            <a href="https://www.doorsey.com/agents" activeClassName="active">
              Agents
            </a>
          </li>
        </>
      )} */}
      {/* {console.log(userLocation, "this is user loca")} */}
      {/* 
      {props.width >= 767 && (
        <li className="nav-item">
          <NavLink
            // to='/property/290'
            to={`/search?cities=${userLocation.get["city"]}&state=${userLocation.get["state"]}&page=1&sortby=1&status=accepting_offers,coming_soon&type=Single Family,Multi Family,Condominium,Mobile / Manufactured&lat=${userLocation.get["lat"]}&long=${userLocation.get["lon"]}`}
            activeClassName="active"
          >
            Search
          </NavLink>
        </li>
      )} */}
      {/* <li className="nav-item">
        <NavLink
          exact
          to={``}
          activeClassName="active"
        >
          Home
        </NavLink>
      </li>
      <li className="nav-item">
        <NavLink
          exact
          to={`/about`}
          activeClassName="active"
        >
          About
        </NavLink>
      </li> */}

      {store_data != (null || undefined) &&
        storeDetails?.headers
          ?.filter((item) => item.label !== "")
          .map((item) => {
            return (
              <li key={item.id}>
                <a href={item.url} target="_blank">
                  {item.label}
                </a>
              </li>
            );
          })}
      <li className="nav-item">
        {/* <NavLink
          exact
          to={`/search?${global.defaultLocation}&group_auction=true`}
          activeClassName={
            window.location.href.includes("group_auction=true")
              ? "active"
              : null
          }
        >
          Auctions
        </NavLink> */}
        <NavLink
          exact
          to={`/search?${global.defaultLocation}&group_auction=true`}
          className={isActive ? "active" : ""}
        >
          Auctions
        </NavLink>
      </li>
      <li className="nav-item">
        <NavLink
          exact
          to={`/search?${global.defaultLocation}&group_auction=false`}
          activeClassName={
            window.location.href.includes("group_auction=false")
              ? "active"
              : null
          }
        >
          Properties
        </NavLink>
      </li>

      {userLoggedIn.get === true ? (
        <>
          {/* {!userDetails.get.customerid || userDetails.get.customerid === "" ? (
            <li className="nav-item">
              <a
                onClick={() => {
                  history.push("/editBidder");
                }}
                className={
                  window.location.pathname.includes("/choose-plan") ||
                  window.location.pathname.includes("/post-listing")
                    ? "active"
                    : null
                }
              >
                Register to Offer
              </a>
            </li>
          ) : (
            ""
          )} */}
          {/* {userDetails.get["profiletype"] === "bidder" ||
          userDetails.get["profiletype"] === "customer" ||
          userDetails.get["profiletype"] === "builder" ? (
            <li className="nav-item">
              <Badge badgeContent={verificationCount} color="primary">
                <NavLink to="/buyerverification" activeClassName="active">
                  Buyer Verification
                </NavLink>
              </Badge>
            </li>
          ) : (
            ""
          )} */}
          {/* {userDetails.get["profiletype"] === "agent" ? (
            <li className="nav-item">
              <Badge badgeContent={verificationCount} color="primary">
                <NavLink to="/agentverification" activeClassName="active">
                  Client and Verification
                </NavLink>
              </Badge>
            </li>
          ) : (
            ""
          )} */}
          {console.log(store_data?.store?.phone, "store_dataCheck")}
          {/* {store_data?.store?.phone ? (
            <li className="nav-item doorsey-no">
              <a href={`tel:${store_data?.store?.phone}`}>
                Talk to us! <span>{store_data?.store?.phone}</span>
              </a>
            </li>
          ) : null} */}
          {store_data !== (null || undefined) ? (
            store_data?.headers?.map((item) => {
              return (
                <li key={item.id}>
                  <a href={item.url}>{item.label}</a>
                </li>
              );
            })
          ) : (
            <>
              <li className="nav-item">
                <NavLink
                  activeClassName="active"
                  to={`/search?${process.env.REACT_APP_DEFAULT_LOCATION}`}
                >
                  View all listings
                </NavLink>
              </li>
              <li className="nav-item">
                <a
                  href="https://www.doorsey.com/submit-a-home"
                  className={
                    window.location.pathname.includes("/choose-plan") ||
                    window.location.pathname.includes("/post-listing")
                      ? "active"
                      : null
                  }
                >
                  Submit a home
                </a>
              </li>
            </>
          )}

          {/* <li className="nav-item">
            <NavLink to="/my-bids" activeClassName="active">
              My Dashboard
            </NavLink>
          </li> */}
          {/* <li className='nav-item'>
            <NavLink
              to='/notifications'
              activeClassName='active'
              className='notify'
            >
              <i className='far fa-bell fs-16'></i>
              <span className='blue-circle'></span>
            </NavLink>
          </li> */}
          <li className="dropdown nav-item">
            <a className="dropdown-toggle">
              <span className="user-circle">
                <i className="fa fa-user"></i>
              </span>{" "}
              {userDetails.get.user_name}
            </a>
            <div className="dropdown-menu">
              <NavLink to={`/profile`} activeClassName="active">
                Profile
              </NavLink>
              <Link
                to=""
                onClick={async () => {
                  logout();
                  localStorage.clear();
                  userLoggedIn.set(false);
                  userDetails.set({});
                  sentInvites.set([]);
                  receivedInvites.set([]);
                  financeDetails.set({});
                  billingDetails.set([]);
                  agentVerificationDetails.set({});
                  history.push(`/login`);
                  refreshPage();
                }}
              >
                Logout
              </Link>
            </div>
          </li>
        </>
      ) : (
        <>
          <li className="nav-item">
            <NavLink
              to={{
                pathname: `/login`,
                state: location,
              }}
              activeClassName="active"
            >
              Login
            </NavLink>
          </li>
          {/* <li className="nav-item">
            <Link
              to={{ pathname: "/signup", state: location }}
              className="btn btn-rg borderViolet"
            >
              Signup
            </Link>
          </li> */}
        </>
      )}
    </ul>
  );
};

export default WebLinks;
