import React, { useState, useEffect } from "react";
import FavoriteCheckbox from "../../atoms/FavoriteCheckbox";
import SearchAuctionCard from "../../atoms/SearchAuctionCard";

const SearchAuctionList = (props) => {
  return (
    <div className="cardCnt">
      {props.properties.map((item, index) => {
        return <SearchAuctionCard item={item} key={index} />;
      })}
    </div>
  );
};

export default SearchAuctionList;
