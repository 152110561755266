import React, { useState, useEffect, useContext, useRef } from "react";
import {
  onImageError,
  onPropertyImageError,
  numberWithCommas,
  setDecimalPoint,
} from "../../../utils";
import PropertyImageGallery from "../Property/PropertyImageGallery";
import PaymentCalculator from "../Property/PaymentCalculator";
import * as CronofyElements from "cronofy-elements";
import "../Property/scheduleTour.css";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import PropertyTimePopup from "../Property/ProprtyTimePopup";
import axios from "axios";
import { getLocation } from "../../../API/userData";
import {
  getAvailability,
  getAvailSlots,
  getPrivateBlock,
  getBidHistory,
  getOfferHistory,
  getOpenBookingBlock,
  getNewToken,
  getPropertyDetails,
} from "../../../API/propertyDetails";
import moment from "moment";
import { toast } from "react-toastify";
import isAuthenticate from "../../../utils/auth";
import { useHistory, useLocation } from "react-router-dom";
import GlobalContext from "../../../context/GlobalContext";
import Tooltip from "@material-ui/core/Tooltip";
import SearchMap from "../Search/SearchMap";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Markup } from "interweave";
import { Button } from "@material-ui/core";
import CustomDialog from "../../atoms/CustomDialog";

const useStyles = makeStyles((theme) => ({
  customFontSize: {
    fontSize: "13px",
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const GroupAuctionLeft = ({
  data,
  propertyDetails,
  lable,
  width,
  bookTour,
  constructAddressComponent,
  bidHistory,
}) => {
  const openNewWindow = (path) => window.open(path, "_blank");
  const [value, setValue] = React.useState(0);
  const [isImageLoaded, setIsImageLoaded] = useState([]);
  const [slotPopup, setSlotPopup] = useState(false);
  const [selectedSlot, setSelectedSlot] = useState({});
  const [agentEmail, setAgentEmail] = useState("");
  const [drawSearch, setDrawSearch] = useState(false);
  const imgRef = useRef(null);
  const [bidHistoryModal, setBidHistoryModal] = useState(false);
  const [offerHistoryModal, setOfferHistoryModal] = useState(false);
  const [propertyDet, setPropertyDet] = useState({});
  const [offerHistoryData, setOfferHistoryData] = useState({});
  const [availableSlots, setAvailableSlots] = useState({});

  const location = useLocation();
  const history = useHistory();
  const { userLoggedIn, userDetails, headerLocation, storeDetails } =
    useContext(GlobalContext);
  /*Slot open pop up*/
  const slotOpenPopup = () => {
    setSlotPopup(true);
  };

  /*Slot close pop up*/
  const slotClosePopup = () => {
    setSlotPopup(false);
  };

  const classes = useStyles();

  const store_id = window.location.pathname.split("/")[1];
  const site_id = localStorage.getItem("site_id");

  /*Common Private Calendar API call*/
  const privateCalendarCall = () => {
    let availCronSlotOpen = {
      id: data.id,
      type: 0,
    };
    console.log(propertyDetails, "propertyDetails");

    getCronAvailForProd(availCronSlotOpen)
      .then((res) => {
        if (res && res.status && res.data && typeof res.data !== "undefined") {
          /*Today's Date*/
          let todaysDate = new Date();
          let today = moment(todaysDate);

          /*Seventh day's Date*/
          let seventhDaysDate = new Date();
          seventhDaysDate.setDate(todaysDate.getDate() + 6);
          let seventhDate = moment(seventhDaysDate);
          let arrayOfDates = getDatesBetweenDates(today, seventhDate).map(
            (ele) => moment(ele).format("MM/DD/YYYY").toString(),
          );

          let objCron = {};
          for (let i = 0; i < arrayOfDates.length; i++) {
            objCron[arrayOfDates[i]] = [];
          }
          for (let j = 0; j < res.data.length; j++) {
            let dataArr = res.data[j];
            for (let i = 0; i < dataArr.available_periods.length; i++) {
              if (
                Object.keys(objCron).includes(
                  moment(dataArr.available_periods[i].start)
                    .format("MM/DD/YYYY")
                    .toString(),
                )
              ) {
                objCron[
                  moment(dataArr.available_periods[i].start).format(
                    "MM/DD/YYYY",
                  )
                ].push(dataArr.available_periods[i]);
              }
            }
          }
          setAvailableSlots(objCron);
        }
      })
      .catch((err) => {
        console.log("err====>", err);
      });
  };

  const blockedSlot = () => {
    if (Number(value) === 0) {
      onIndividualPrivateSlot(selectedSlot);
    } else if (Number(value) === 1) {
      onIndividualOpenSlot(selectedSlot);
    }
  };

  const agentEmailAddressChng = (value, radioValue) => {
    setAgentEmail(value);
  };

  /*API call for the Cronify Avail Slot*/
  const getCronAvailForProd = async (cronAvail) => {
    try {
      let data = await getAvailSlots(cronAvail, site_id);
      return data;
    } catch (error) {
      throw error;
    }
  };

  /* Array for seven days*/
  const getDatesBetweenDates = (startDate, endDate) => {
    let dates = [];
    //to avoid modifying the original date
    const theDate = new Date(startDate);
    while (theDate < endDate) {
      dates = [...dates, new Date(theDate)];
      theDate.setDate(theDate.getDate() + 1);
    }
    dates = [...dates, endDate];
    return dates;
  };

  /*Saving the open house slot function */
  const onIndividualOpenSlot = async (invSlot) => {
    let params = {
      slot: invSlot,
    };
    let propertyDtls = {
      id: data.id,
      email:
        isAuthenticate() !== undefined ? userDetails.get.email : agentEmail,
    };

    let data__ = await getNewToken(site_id);

    if (data__.status) {
      let data_ = await getOpenBookingBlock(propertyDtls, params, site_id);
      if (data_.status) {
        toast.success("Open houses slot has been booked successfully");
        setSlotPopup(false);
      }
    }
  };
  /*Saving the Private slot function */
  const onIndividualPrivateSlot = async (invSlot) => {
    let params = {
      slot: encodeURI(JSON.stringify(invSlot)),
    };
    let propertyDtls = {
      id: data.id,
      agentEmail:
        agentEmail && agentEmail.length > 0
          ? agentEmail
          : userDetails.get.email,
    };

    try {
      let data__ = await getNewToken(site_id);
      if (data__.status) {
        let data_ = await getPrivateBlock(propertyDtls, params, site_id);
        if (data_.status) {
          setSlotPopup(false);
          toast.success("Private tour slot has been booked successfully");
          privateCalendarCall();
          setAgentEmail("");
        } else {
          toast.error(data_.message);
          setSlotPopup(false);
          setAgentEmail("");
        }
      }
    } catch (err) {
      console.log("err===>", err);
    }
  };

  useEffect(() => {
    if (data && Object.keys(data).length > 0 && typeof data !== "undefined") {
      privateCalendarCall();
    }
  }, [data]);

  const getPropertyData = async (id) => {
    let prodData = await getPropertyDetails(data?.id, site_id);
    if (prodData.status === true) {
      setPropertyDet(prodData.result);
    }
  };

  // const fetchOfferHistory = async () => {
  //   console.log(site_id, "site.id");
  //   let offerResult = await getOfferHistory({ id: data?.id + "" }, site_id);
  //   console.log(offerResult, "offerResult");
  //   if (offerResult.status === true) {
  //     setOfferHistoryData(offerResult.result);
  //   }
  // };

  const fetchOfferHistory = async () => {
    const url = window.location.pathname; // Get the current URL path
    const id = url.split("/auction-group/")[1]?.split("/")[0]; // Extract the id after /auction-group/

    console.log(id, "Extracted ID");

    let offerResult = await getOfferHistory({ id: id }, site_id);
    console.log(offerResult, "offerResult");

    if (offerResult.status === true) {
      setOfferHistoryData(offerResult.result);
    }
  };

  const handleOfferHistory = () => {
    setOfferHistoryModal(true);
    fetchOfferHistory();
  };

  useEffect(() => {
    getPropertyData();
  }, [data?.id, site_id]);

  console.log(data, "datat123");

  return (
    <>
      <div className="pv-left-top">
        {lable && Object.keys(lable).length && (
          <PropertyImageGallery
            lable={lable}
            width={width}
            groupAuction
            data={data}
          />
        )}
        <div className="row pt-3 pb-3" style={{ fontWeight: "normal" }}>
          <div className="col-md-6 col-lg-7">
            <div
              className="property-sub-head"
              onClick={() =>
                window.open(
                  `https://www.google.com/maps/search/?api=1&query=${data.lat},${data.lng}`,
                  "_blank",
                )
              }
              style={{ cursor: "pointer" }}
            >
              {data.house_number
                ? constructAddressComponent(
                    data.loc_address,
                    data.zipcode,
                    data.house_number,
                  )
                : constructAddressComponent(
                    data.loc_address,
                    data.zipcode,
                    "",
                    data.loc_city,
                    data.state,
                  )}
            </div>
          </div>
          <div className="col-md-6 col-lg-5">
            <div className="pd-op-center justify-content-end">
              {data.listing_type !== 2 ? (
                <>
                  <div>
                    {data.bedroom_type ? (
                      <span>{data.bedroom_type} Bd</span>
                    ) : (
                      ""
                    )}
                  </div>
                  <div>
                    {data.bathroom ? <span>{data.bathroom} Ba</span> : ""}
                  </div>
                </>
              ) : null}

              <div>
                <span>
                  {data.listing_type && data.sqrtarea
                    ? `${numberWithCommas(data.sqrtarea)} ${
                        data.listing_type == 2 ? "Acres" : "sq. ft."
                      }`
                    : null}
                  {/* {`${data.sqrtarea ? numberWithCommas(data.sqrtarea) : 0} ${
                    data.listing_type === 2 ? "Acres" : "sqft"
                  }`} */}
                </span>
              </div>
            </div>
          </div>
        </div>
        <p className="propertyDesc">
          <Markup content={data.description} />
        </p>

        {/* <PaymentCalculator
          data={data}
          isDraw={drawSearch}
          propertyDetails={propertyDetails}
        /> */}

        {/* <div className="property-head">Book a tour</div> */}

        {/* <div className="schedule" ref={bookTour}>
          <p>
            {data.book_tour ? (
              <>
                <span className="schedule-link">
                  <a href={data.book_tour} target="_blank">
                    Click here
                  </a>{" "}
                </span>
                to instantly book a tour that fits your schedule.
              </>
            ) : (
              <>Please contact listing agent above to schedule a tour.</>
            )}
          </p>
        </div> */}
      </div>

      <div className="bidHistoryCnt">
        {Boolean(data?.auction) ? (
          <Button variant="outlined" onClick={() => setBidHistoryModal(true)}>
            <span className="material-icons">history</span>
            View Bid History
          </Button>
        ) : null}
        {Boolean(data?.offer) ? (
          <Button variant="outlined" onClick={handleOfferHistory}>
            <span className="material-icons-outlined">local_offer</span>
            View Offer History
          </Button>
        ) : null}
      </div>
      {console.log(data, "dataaa")}

      {slotPopup ? (
        <PropertyTimePopup
          open={slotPopup}
          onClose={slotClosePopup}
          selectSlot={selectedSlot}
          data={data}
          seletedTab={value}
          setEmailAddressChng={agentEmailAddressChng}
          agentEmailAdd={agentEmail}
          onClickConfirmTour={blockedSlot}
        />
      ) : null}

      <CustomDialog
        title="Bid History"
        open={bidHistoryModal}
        dialogClass="bidHistoryModal"
        handleClose={() => setBidHistoryModal(false)}
      >
        <table className="table">
          <thead>
            <tr>
              <th>Username</th>
              <th>Date</th>
              <th>Proposed amount</th>
              <th>Downpayment</th>
              <th>Financing</th>
            </tr>
          </thead>
          <tbody>
            {bidHistory?.length ? (
              <>
                {bidHistory?.map((data, index) => (
                  <tr key={index}>
                    <td>{data.username}</td>
                    <td>
                      {moment(data.created_at).format("MMM DD")} at{" "}
                      {moment(data.created_at).format("hh:mm A")}
                    </td>
                    <td>
                      {data.proposed_amount
                        ? `$${setDecimalPoint(
                            parseInt(data.proposed_amount, 10),
                          )}`
                        : "-"}
                    </td>
                    <td>
                      {data.down_payment
                        ? `${setDecimalPoint(parseInt(data.down_payment, 10))}%`
                        : "-"}
                    </td>
                    <td>
                      {data.fin_type === "no_fin_type"
                        ? "No Financing Type"
                        : data.fin_type}
                    </td>
                  </tr>
                ))}
              </>
            ) : (
              <tr>
                <td colSpan={5} className="text-center">
                  No records found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </CustomDialog>
      {console.log(offerHistoryData, "offerHistoryData")}
      <CustomDialog
        title="Offer History"
        open={offerHistoryModal}
        dialogClass="bidHistoryModal"
        handleClose={() => setOfferHistoryModal(false)}
      >
        <table className="table">
          <thead>
            <tr>
              <th>Username</th>
              <th>Date</th>
              <th>Proposed amount</th>
              <th>Downpayment</th>
              <th>Financing</th>
            </tr>
          </thead>
          <tbody>
            {offerHistoryData?.length ? (
              <>
                {offerHistoryData?.map((data, index) => (
                  <tr key={index}>
                    <td>{data.username}</td>
                    <td>
                      {moment(data.created_at).format("MMM DD")} at{" "}
                      {moment(data.created_at).format("hh:mm A")}
                    </td>
                    <td>
                      {data.proposed_amount
                        ? `$${setDecimalPoint(
                            parseInt(data.proposed_amount, 10),
                          )}`
                        : "-"}
                    </td>
                    <td>
                      {data.down_payment
                        ? `${setDecimalPoint(parseInt(data.down_payment, 10))}%`
                        : "-"}
                    </td>
                    <td>
                      {data.fin_type === "no_fin_type"
                        ? "No Financing Type"
                        : data.fin_type}
                    </td>
                  </tr>
                ))}
              </>
            ) : (
              <tr>
                <td colSpan={5} className="text-center">
                  No records found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </CustomDialog>
    </>
  );
};

export default GroupAuctionLeft;
